import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DiariaService } from 'src/app/core/services/diaria.service';
import { SelectOption } from 'src/app/models/common/angular-material-ui/select-option.interface';
import { DiariaReason } from 'src/app/models/diarias/diaria-reason.interface';

@Component({
  selector: 'app-approval-request-form',
  templateUrl: './approval-request-form.component.html',
  styleUrls: ['./approval-request-form.component.scss']
})
export class ApprovalRequestFormComponent implements OnInit, OnChanges {
  @Input() isLoading = false;
  @Input() formGroup: FormGroup;
  @Input() responsibleClients: Array<string> = [];
  reasons: Array<DiariaReason>;
  responsibleAreaOptions: Array<SelectOption> = [];

  reasonFormControl = new FormControl('');
  reasonsOptions: Observable<Array<SelectOption>>;

  subReasonFormControl = new FormControl('');
  subReasonsOptions: Observable<Array<SelectOption>>;

  responsibleClientFormControl = new FormControl('');
  responsibleClientsAllOptions: Array<SelectOption>;
  responsibleClientsOptions: Observable<Array<SelectOption>>;

  get isAreaSelected(): boolean { return this.formGroup.get('areaResponsavel').value; }
  get isReasonSelected(): boolean { return this.formGroup.get('motivo').value; }

  constructor(private diariaService: DiariaService) {
  }

  ngOnInit(): void {
    (async () => {
      void this.loadResponsibleAreasOptions();
      await this.loadReasons();
      this.registerReasonsOptionsObservable();
      this.registerSubReasonsOptionsObservable();
    })();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { responsibleClients } = changes;
    const responsibleClientsChanged =
      responsibleClients.currentValue && responsibleClients.currentValue !== responsibleClients.previousValue;
    if (responsibleClientsChanged) {
      this.registerResponsibleClientsOptionsObservable(responsibleClients.currentValue);
    }
  }

  private async loadResponsibleAreasOptions(): Promise<void> {
    const areas = await this.diariaService.getAllResponsibleAreas();
    this.responsibleAreaOptions = areas.map(({ descricao, key }) => ({ label: descricao, value: key }));
  }

  private async loadReasons(): Promise<void> {
    this.reasons = await this.diariaService.getAllReasons();
  }

  private getReasonsByArea(area: string): Array<DiariaReason> {
    return this.reasons.filter(reason => reason.areaResponsavel === area);
  }

  private registerReasonsOptionsObservable(): void {
    const getReasonsOptionsFromArea = (area: string): Observable<Array<SelectOption>> => {
      const reasons = this.getReasonsByArea(area);
      const reasonsOptions = reasons.map(({ descricao }) => ({ label: descricao, value: descricao }));
      return this.createFilteredOptions(reasonsOptions, this.reasonFormControl);
    };

    this.reasonsOptions = getReasonsOptionsFromArea(this.formGroup.get('areaResponsavel').value);
    this.formGroup.get('areaResponsavel').valueChanges.subscribe((area: string) => {
      this.formGroup.get('motivo').setValue('');
      this.reasonsOptions = getReasonsOptionsFromArea(area);
    });
  }

  private registerSubReasonsOptionsObservable(): void {
    const getSubReasonsOptions = (reasonDescription: string): Observable<Array<SelectOption>> => {
      const selectedReason = this.reasons.find(reason => reason.descricao === reasonDescription);
      const subReasonsOptions = selectedReason?.subMotivos.map(({ descricao }) => ({ label: descricao, value: descricao })) || [];
      if (subReasonsOptions.length === 1) {
        this.formGroup.get('subMotivo').setValue(subReasonsOptions[0].value);
      }
      return this.createFilteredOptions(subReasonsOptions, this.subReasonFormControl);
    };

    this.subReasonsOptions = getSubReasonsOptions(this.formGroup.get('motivo').value);
    this.formGroup.get('motivo').valueChanges.subscribe((reasonDescription: string) => {
      this.formGroup.get('subMotivo').setValue('');
      if (reasonDescription) {
        this.subReasonsOptions = getSubReasonsOptions(reasonDescription);
      }
    });
  }

  private registerResponsibleClientsOptionsObservable(responsibleClients: Array<string>): void {
    this.responsibleClientsAllOptions = responsibleClients.map((client: string) => ({ label: client, value: client }));
    this.responsibleClientsOptions = this.createFilteredOptions(this.responsibleClientsAllOptions, this.responsibleClientFormControl);
  }

  private createFilteredOptions(options: Array<SelectOption>, formControl: FormControl): Observable<Array<SelectOption>> {
    return formControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterOptionsBySearchValue(options, value))
    );
  }

  private filterOptionsBySearchValue(options: Array<SelectOption>, searchValue: string): Array<SelectOption> {
    const lowerCaseSearchValue = searchValue.toLowerCase().trim();
    if (lowerCaseSearchValue.length === 0) {
      return options;
    }

    return options.filter(({ label }) => {
      const labelWords = label.toLowerCase().split(' ');
      return labelWords.some(word => word.startsWith(lowerCaseSearchValue));
    });
  }
}
