import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { BaseComponent } from 'src/app/components/shared/base.component';
import { BreadCrumbItem } from 'src/app/core/models/bread-crumb-item.model';
import { MyFilter } from 'src/app/core/models/my-filter.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { DiariaService } from 'src/app/core/services/diaria.service';
import { GDFilterService } from 'src/app/core/services/gd-filter.service';
import { MenuService } from 'src/app/core/services/menu.service';
import { PerfilPermissaoService } from 'src/app/core/services/perfil-permissao.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { MessageService } from 'src/app/core/services/message.service';
import { Diarias } from 'src/app/models/diarias.model';
import { DiariaApprovalRequest } from 'src/app/models/diarias/diaria-approval.interface';
import { DialogFiltroService } from 'src/app/services/dialog-filtro.service';
import { createManualApprovalFormGroup } from 'src/app/pages/daily/utils/create-manual-approval-form-group';

@Component({
  selector: 'app-daily',
  templateUrl: './daily.component.html',
  styleUrls: ['./daily.component.scss']
})
export class DailyComponent extends BaseComponent implements OnInit {

  logistic: string;
  listaDados: Diarias[] = [];
  termoPesquisa = '';
  listaDadosCompleta: Diarias[] = [];
  myFilter: MyFilter;
  loading = true;
  customFilter: object | null = null;
  quantityKeysCustomFilter = 0;
  canEdit = false;
  dateFilterText = '';
  dateStartFilter = new FormControl('');
  dateEndFilter = new FormControl('');
  shouldDisplaySelectAllDiarias = false;
  selectAllDiarias = new FormControl(false);
  diariasSelectedForApproval = new FormControl([]);

  batchApprovalFormGroup = createManualApprovalFormGroup();

  @ViewChild('batchApprovalRequestFormDialog') batchApprovalRequestFormDialogTemplate: TemplateRef<any>;

  private readonly rolesWithEditPermission = ['GestaoDiariasEdicao', 'MonitorDistribuicao', 'AdminDistribuicao', 'GestorDistribuicao'];

  listHeaders: string[] = [
    'Data', 'Chat', 'Placa', 'Aprovação/ Viagem', 'Tempo em Diária', 'Peso', 'Cliente', 'Entrega', 'Motivo', 'Status'
  ];

  readonly tooltipsByColor: Record<string, string> = {
    Cinza: 'Diária em processo de aprovação',
    Verde: 'A diária da viagem foi aprovada ou entregue. Clique para analisar',
    Laranja: 'A diária da viagem não foi aprovada no tempo necessário. Clique para analisar',
    Vermelho: 'A diária da viagem foi reprovada. Clique para analisar',
  };

  constructor(
    protected authService: AuthService,
    protected router: Router,
    protected menuService: MenuService,
    protected sessionStorageService: SessionStorageService,
    protected perfilPermissaoService: PerfilPermissaoService,
    private dialogService: DialogFiltroService,
    private service: DiariaService,
    private filterService: GDFilterService,
    private matDialog: MatDialog,
    private messageService: MessageService,
  ) {
    super(authService, router, menuService, sessionStorageService, perfilPermissaoService, 'diarias', '#F9FAFB');
    this.canEdit = this.rolesWithEditPermission.includes(this.perfilPermissaoService.getCurrentLogisticaRole().codigo);
  }

  async ngOnInit(): Promise<void> {
    this.checkAuth();
    this.logistic = this.perfilPermissaoService.getCurrentLogisticaRole().logistica;
    this.menuService.setBreadCrumb([
      new BreadCrumbItem('MENU.HOME'),
      new BreadCrumbItem(' Diárias', '/diarias')
    ]);
    this.dateStartFilter.setValue(this.filterService.dateFilter.start);
    this.dateEndFilter.setValue(this.filterService.dateFilter.end);
    this.batchApprovalFormGroup.get('clienteResponsavel').disable();

    if (!this.filterService.myFilters) {
      await this.fetchUserSavedFilters();
    }
    void this.fetchAllDiariasBasedOnFilter();
    this.service.listaDiariasObservable.subscribe((data: Diarias[]) => {
      this.listaDados = data;
      this.shouldDisplaySelectAllDiarias = this.listaDados.some(dado => dado.status.toLowerCase() === 'pendente');
      this.selectAllDiarias.setValue(false);
      this.diariasSelectedForApproval.setValue([]);
    });
    this.service.loadingDiariasObservable.subscribe((data: boolean) => this.loading = data);
    this.filterService.myFilterSelectedObservable.subscribe((data: MyFilter) => this.myFilter = data);
    this.service.listaDiariasCompletaObservable.subscribe((data: Diarias[]) => this.listaDadosCompleta = data);

    this.filterService.customFilterObservable.subscribe((data: object | null) => {
      this.customFilter = data;
      if (data != null) {
        const filterValues = Object.values(this.customFilter);
        this.quantityKeysCustomFilter = filterValues.filter((element) => Array.isArray(element) ? element.length > 0 : !!element).length;
      }
    });
  }

  openDialogFiltro(): void {
    this.dialogService.openDialogFiltro({
      useMyFilter: false
    }).afterClosed().subscribe(() => {
      this.filterDiariasByDate();
    });
  }

  openDialogListFiltros(): void {
    this.dialogService.openDialogListFiltros().afterClosed().subscribe(() => {
      this.filterDiariasByDate();
    });
  }

  redirectToManualRequest(): void {
    if (this.canEdit) {
      void this.router.navigate(['diarias', 'sinalizar']);
    }
  }

  openBatchApprovalRequestFormDialog(): void {
    this.matDialog.open(this.batchApprovalRequestFormDialogTemplate, { width: '1000px' });
  }

  async submitBatchApprovalRequestForm(): Promise<void> {
    const batchApprovalRequest = this.buildBatchApprovalRequest();
    const response = await this.service.postApprovalRequest(batchApprovalRequest).toPromise();
    if (!response.success) {
      return this.messageService.error('Ocorreu um erro ao enviar a aprovação');
    }
    this.messageService.success('Diárias enviadas para aprovação com sucesso');
    this.selectAllDiarias.setValue(false);
    this.diariasSelectedForApproval.setValue([]);
    void this.fetchAllDiariasBasedOnFilter();
    this.matDialog.closeAll();
  }

  private buildBatchApprovalRequest(): DiariaApprovalRequest {
    return {
      idDiaria: this.diariasSelectedForApproval.value,
      areaResponsavel: this.batchApprovalFormGroup.get('areaResponsavel').value,
      motivo: this.batchApprovalFormGroup.get('motivo').value,
      subMotivo: this.batchApprovalFormGroup.get('subMotivo').value,
      clienteResponsavel: this.batchApprovalFormGroup.get('clienteResponsavel').value,
      justificativa: this.batchApprovalFormGroup.get('justificativa').value,
      idUsuarioLogado: this.user.preferred_username,
    };
  }

  handleSelectDiaria(id: string): void {
    const field = this.diariasSelectedForApproval;
    const newValue = field.value.includes(id) ? field.value.filter((value: string) => value !== id) : [...field.value, id];
    field.setValue(newValue);
  }

  handleSelectAllDiarias(): void {
    const newValue = !this.selectAllDiarias.value ? [] : this.listaDados
      .filter(dado => dado.status.toLowerCase() === 'pendente')
      .map(dado => dado.id);
    this.diariasSelectedForApproval.setValue(newValue);
  }

  async fetchAllDiariasBasedOnFilter(): Promise<void> {
    const defaultFilter = this.filterService.myFilterSelected;
    const parsedDefaultFilter = defaultFilter ? JSON.parse(defaultFilter.definicaoFiltro) : undefined;
    const customFilter = this.filterService.customFilter as  Record<string, string | string[]>;
    const result = await this.service.getAllDiarias(customFilter || parsedDefaultFilter);
    this.service.listaDiariasCompleta = result.map((element: any) => new Diarias(element));
    this.filterDiariasByDate();
    this.service.loadingDiarias = false;
  }

  private async fetchUserSavedFilters(): Promise<void> {
    const userFiltersResponse = await this.service.getMyFilters(JSON.parse(localStorage.getItem('currentUser')).email);
    const userFilters = userFiltersResponse.map((element: any) => new MyFilter(element));
    const defaultFilter = userFilters.filter((element: MyFilter) => element.filtroPadrao)[0];
    this.filterService.myFilters = userFilters;
    this.filterService.myFilterSelected = defaultFilter;
  }

  filtrarDados(): void {
    if (!this.termoPesquisa || this.termoPesquisa === '') {
      this.service.listaDiarias = this.listaDadosCompleta;
      return;
    } else {
      this.service.listaDiarias = this.listaDadosCompleta.filter((dado: Diarias) => {

        return Object.values(dado).some((value: string) => {
          return value && typeof value === 'string' && value.toLowerCase().includes(this.termoPesquisa.toLowerCase());
        });
      });
    }
  }

  clearDate(): void {
    this.dateStartFilter.setValue('');
    this.dateEndFilter.setValue('');
    this.filterDiariasByDate();
  }

  filterDiariasByDate(): void {
    const dateStart: Date | undefined = this.dateStartFilter.value;
    const dateEnd: Date | undefined = this.dateEndFilter.value;
    this.filterService.dateFilter.start = dateStart;
    this.filterService.dateFilter.end = dateEnd;
    if (!dateStart || !dateEnd) {
      this.dateFilterText = '';
      this.service.listaDiarias = this.listaDadosCompleta;
      return;
    }

    this.dateFilterText = `${formatDate(dateStart, 'dd/MM/yyyy', 'pt-BR')} - ${formatDate(dateEnd, 'dd/MM/yyyy', 'pt-BR')}`;
    dateStart.setHours(0);
    dateStart.setMinutes(0);
    dateStart.setSeconds(0);
    dateStart.setMilliseconds(0);

    dateEnd.setHours(23);
    dateEnd.setMinutes(59);
    dateEnd.setSeconds(59);
    dateEnd.setMilliseconds(999);

    const startDateFilterTimestamp = dateStart.getTime();
    const endDateFilterTimestamp = dateEnd.getTime();
    this.service.listaDiarias = this.listaDadosCompleta.filter((diaria: Diarias) => {
      const diariaRequestTimestamp = diaria.dataSolicitacao.getTime();
      return diariaRequestTimestamp >= startDateFilterTimestamp && diariaRequestTimestamp <= endDateFilterTimestamp;
    });
  }

  getStatusCount(...statuses: string[]): number {
    return this.listaDados.reduce((count, item) => statuses.includes(item.status) ? count + 1 : count, 0);
  }

  getQuantidadePendentes(): number {
    return this.getStatusCount('Pendente');
  }

  getQuantidadeEmAprovacao(): number {
    return this.getStatusCount('Em Aprovação');
  }

  getQuantidadeAnalisadas(): number {
    return this.getStatusCount('Analisada');
  }

  getQuantidadeFinalizadas(): number {
    return this.getStatusCount('Finalizada', 'Aprovada', 'Entregue');
  }

  getQuantidadeDentroDoPlanejamentoDeEntrega(): number {
    return this.getStatusCount('Dentro do planejamento de entrega');
  }

  calcularProgresso(item: Diarias): number {
    const [atual, total] = item.numeroEntrega.split('/').map(Number);
    return (atual / total) * 100;
  }

  chatRedirect(event: MouseEvent, viagem: string, placa: string): void {
    event.preventDefault();
    event.stopPropagation();

    if (this.canEdit) {
      void this.router.navigate(['chat', 'viagem', viagem, 'placa', placa]);
    }
  }
}
