
export const environment = {
  production: true,

  // azure ad
  tenantId: '27951710-ebb9-4efb-a4a5-48e460822d4b',
  clientId: 'ad40953f-c002-4ec0-96e1-15b5ac87e1e8',

  base_api: 'https://apim-qas.brf.cloud/portal-d/api',
  base_signal_r_hub: 'https://apim-qas.brf.cloud/hub-d/api',

  // APIM Primary
  base_api_primary: 'https://apim-qas.brf.cloud/portal-p/api',
  base_signal_r_hub_primary: 'https://apim-qas.brf.cloud/hub-p/api',

  subscription_portal_primary: '9d574b33b44140c3a5e3049055b77de8',
  subscription_hub_primary: '23572d3104ee4277b7e7adb9c322f393',

  // APIM Distribution
  base_api_distribution: "https://apim-qas.brf.cloud/portal-d/api",
  base_api_distribution_diaria: "https://apim-qas.brf.cloud/portal-gd/api",
  base_signal_r_hub_distribution: "https://brf-fnc-onelog-proc-hub-qas.azurewebsites.net/api",

  subscription_portal_distribution: "ff0758b0c40c4b0993f401c7074e6d92",
  subscription_portal_distribution_diaria: "a7586301c4dc4c31b201a8709c9ca47c",
  subscription_hub_distribution: "35cf65673e0a4cedbb8b4b8a79ea01f9",

  // APIM Commodities / Agro
  base_api_agro_commodities: 'https://apim-qas.brf.cloud/portal-ac/api',
  base_signal_r_hub_agro_commodities: 'https://apim-qas.brf.cloud/portal-ac/api',

  subscription_portal_agro_commodities: '28460ae288d948ad829f7c61cc9c4285',
  subscription_hub_agro_commodities: '28460ae288d948ad829f7c61cc9c4285',

  label_authorization_key: 'Ocp-Apim-Subscription-Key',

  googleMapsApi: {
    API_KEY: 'AIzaSyBm2Ln1E2KtNTS7yPmnb6Y9IqUxrRMj7Ns',
    VERSION: "weekly"
  },

  firebase: {
    apiKey: "AIzaSyB5fRxHCmZjvqy9lhcZLzRUbV1aagA3uYo",
    authDomain: "brf-one-log.firebaseapp.com",
    databaseURL: "https://brf-one-log-default-rtdb.firebaseio.com",
    projectId: "brf-one-log",
    storageBucket: "brf-one-log.appspot.com",
    messagingSenderId: "236396290885",
    appId: "1:236396290885:web:70652dd0cc87728300142c",
    measurementId: "G-1NERZJ8DT6",

  },

  vapidKey: "BNlUVWZdakgVLf6k1mzuSzzp9ch5p1a3pGOIi_k9zoAeI00nNRJPZgJuFAy4c2FnsrDIWsPa8DSnwzYjKfz_WK8",

  powerbi: {
    airport: "https://app.powerbi.com/reportEmbed?reportId=130214d0-4a80-4cbd-b747-7e28ed3048e2&autoAuth=true&ctid=27951710-ebb9-4efb-a4a5-48e460822d4b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D",
    availability: "https://app.powerbi.com/reportEmbed?reportId=b8156aea-35bf-477b-86bf-3bed1867f9fa&autoAuth=true&ctid=27951710-ebb9-4efb-a4a5-48e460822d4b",
    track: "https://app.powerbi.com/reportEmbed?reportId=56cea688-3e46-4ea2-84c8-038de81b3280&autoAuth=true&ctid=27951710-ebb9-4efb-a4a5-48e460822d4b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D"
  }

};
