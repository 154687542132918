import { PointV2Size } from "../../point-v2.model";
import { ISynopticMapPointModel } from "../synoptic-map-point.interface";
import { SynopticMapPoint } from "../synoptic-map-point.model";

export class SynopticMapPointAgroSlaughterAnimal
    extends SynopticMapPoint<SynopticMapPointAgroSlaughterAnimal>
    implements ISynopticMapPointModel<SynopticMapPointAgroSlaughterAnimal>  {

    constructor(point: any) {
        super(point);
    }

    get title(): string {        
        switch (this.type) {
            case 'inicio_rota':
                return 'Inicio da viagem';
            case 'origem':
                return this.origin;
            case 'retorno_frigorífico':
                return 'Retorno Frigorífico';
            case 'retorno_incubatório':
                return 'Retorno ao incubatório'
            case 'entrega':
                return 'Entrega no integrado';
            case 'coleta':
                return `Coleta ${this.collect}`;
            case 'fim_rota':
                return 'Término da viagem';            
            case 'in_transit':
                return 'Em trânsito';            
        }
    }

    get color(): string {
        if (this.type === 'in_transit') {
            return 'current';
        }

        if (this.type === 'anomaly' || this.anomalies?.length > 0) {
            return 'delay';
        }

        if (this.unloadingNoteDate !== null && this.unloadingNoteDateFinished === null) {
            return 'current';
        }

        if (this.unloadingNoteDate !== null && this.unloadingNoteDateFinished !== null && this.status != 'criado') {
            return 'ok';
        }

        return 'default';
    }

    get current(): boolean {
        if (this.type === 'anomaly') {
            return false;
        }

        if (this.type === 'in_transit') {
            return true;
        }

        if (this.unloadingNoteDate !== null && this.unloadingNoteDateFinished === null) {
            return true;
        }

        return false;
    }

    get date(): Date {
        if (this.unloadingNoteDateFinished) {
            return new Date(this.unloadingNoteDateFinished);
        }

        if (this.unloadingNoteDate) {
            return new Date(this.unloadingNoteDate);
        }

        return null;
    }

    get urlPoint(): string {
        if (this.type === 'in_transit') {
            return `/load/${this.loadNumber}`;
        }

        return `/load/${this.loadNumber}/travel-steps/${this.id}`;
    }

    get subtitle(): string {
        if (this.type === 'inicio_rota' || this.type === 'fim_rota') {
            return null;
        }

        if (this.code) {
            return `${this.code} - ${this.name}`;
        }

        return this.name;
    }

    get anomalyName(): string {
        if (this.anomalies === null || this.anomalies?.length === 0) {
            return null;
        }

        return this.anomalies.find(anomaly => !anomaly.isDone)?.name;
    }

    get anomalyUrl(): string {
        if (this.type === 'anomaly') {
            return `/load/${this.loadNumber}/anomaly/${this.id}`;
        }

        if (this.anomalies === null || this.anomalies?.length === 0) {
            return null;
        }

        const anomalyFinded = this.anomalies.find(anomaly => !anomaly.isDone);

        return anomalyFinded !== null
            ? `/load/${this.loadNumber}/anomaly/${anomalyFinded?.id}`
            : null;
    }

    get size(): PointV2Size {
        if (this.type === 'anomaly') {
            return PointV2Size.ExtraSmaller;
        }

        if (this.anomalies === null || this.anomalies?.length === 0 || this.anomalies?.length <= 9) {
            return this.current ? PointV2Size.Medium : PointV2Size.Smaller;
        }

        return this.current ? PointV2Size.Bigger : PointV2Size.Medium;
    }
}
