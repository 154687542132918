import { AfterViewInit, ChangeDetectorRef, Component, Input, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { TOption } from 'src/app/components/select/select.component';
import { LogisticTypeEnum } from 'src/app/core/models/enums/logistic-type.enum';
import { RotaFilter } from 'src/app/core/models/filters/rota-filter';
import { Point } from 'src/app/core/models/point.model';
import { RastroViagem } from 'src/app/core/models/rastro-viagem.model';
import { AnomalyService } from 'src/app/core/services';
import { MonitoramentoService } from 'src/app/core/services/monitoramento.service';
import { SignalRService } from 'src/app/core/services/signal-r.servirce';
import { ViagemService } from 'src/app/core/services/viagem.service';
import { Coordinate } from 'src/app/models/google-maps/coordinate.model';
import { SynopticMapService } from 'src/app/services/synoptic-map/synoptic-map.service';

export enum TypeChecked {
  ANOMALIES = 'anomalies',
  STEPS_PROVIDED = 'stepsProvided',
  STEPS_ACHIEVED = 'stepsAchieved',
  TRAIL_PROVIDED = 'trailProvided',
  TRAIL_ACHIEVED = 'trailAchieved',
  STEPS_JUSTIFIED = 'stepsJustified',
  TRAIL_DETAIL = 'trailDetail'
}

@Component({
  selector: 'app-google-map-load-steps-tracking',
  templateUrl: './load-steps-tracking.component.html'
})
export class GoogleMapLoadStepsTrackingComponent implements AfterViewInit {

  @Input() public logistic: string;

  @Input() public loadNumber: string;

  @Input() public step: any = {};

  @Input() public pointId: string;

  @Input() public showArrowDirection: boolean = false;

  @Input() public points: Point[] = [];

  @Input() listCollectionAndDelivery: any[];

  @Input() currentPosition: RastroViagem;

  @Input() currentPoint: RastroViagem;

  distance: number = 0;

  mapPosition: Coordinate = null;

  height = '300px';

  public traceListData: RastroViagem[] = [];

  public traceListDataProvided: RastroViagem[] = [];

  statusOptions: TOption[] = [
    { display: "Anomalia", key: 0, value: 'anomalies', checked: true },
    { display: "Paradas previstas", key: 1, value: 'stepsProvided', checked: true },
    { display: "Paradas realizadas", key: 2, value: 'stepsAchieved', checked: true },
    { display: "Rastro previsto", key: 3, value: 'trailProvided', checked: true },
    { display: "Rastro realizado", key: 4, value: 'trailAchieved', checked: true },
    { display: "Paradas justificadas", key: 5, value: 'stepsJustified', checked: true },
    { display: "Rastro otimizado", key: 6, value: 'trailDetail', checked: false }
  ]

  public loading = false;

  private changeVisualizationOnMap: Subject<{ type: string | number, checked: boolean }> = new Subject();

  constructor(
    private viagemService: ViagemService,
    private _cd: ChangeDetectorRef,
    private monitoramentoService: MonitoramentoService,
    protected signalRService: SignalRService,
    protected synopticMapService: SynopticMapService,
  ) {
  }

  ngAfterViewInit(): void {

  }


  async ngOnChanges(changes: SimpleChanges) {
    this.step = changes.step?.currentValue
    this.currentPosition = changes.currentPosition?.currentValue
    this.loading = true;
    let parameters = new RotaFilter();
    parameters.logistica = this.logistic;
    parameters.numeroViagem = [this.loadNumber.toString()];
    this.viagemService
      .getRastroViagem(this.logistic, this.loadNumber)
      .subscribe(async (traceLoad: RastroViagem[]) => {
        if (this.logistic === LogisticTypeEnum.PRIMARY) {
          const route = await this.monitoramentoService.getAll(parameters, false).toPromise();

          this.traceListData = traceLoad.map(x => {
            const index = route.rotas[0].paradas.findIndex(step => step.id === x?.parada?.id);
            if (index > -1) {
              return { ...x, radius: route.rotas[0].paradas[index].raioKm }
            } else {
              return { ...x, radius: 0 }
            }
          });
        }
        if (this.logistic === LogisticTypeEnum.DISTRIBUITION) {
          this.traceListData = await this.buildTrace(this.listCollectionAndDelivery, traceLoad);
          this.height = '800px';
        }
        if (this.logistic === LogisticTypeEnum.AGRONOMY || this.logistic === LogisticTypeEnum.COMMODITIES) {
          this.traceListData = traceLoad;
        }
        const item = this.traceListData.filter(item => item?.parada?.id === this.pointId)[0] || null;

        if (item) {
          this.currentPoint = new RastroViagem({
            latitude: item?.latitudeRealizada || item.latitude,
            longitude: item?.longitudeRealizada || item.longitude,
            anomalia: null,
            dataHora: null,
            parada: item?.parada || null,
            tipoObj: null,
            radius: 0
          })
        }
        if (this.logistic === LogisticTypeEnum.DISTRIBUITION) {
          this.distance = null;
          this.mapPosition = null;
          if (!item.latitudeRealizada && !item.longitudeRealizada) {
            let p2 = this.currentPosition;
            if (!p2) p2 = this.traceListData[this.traceListData.length - 1];
            const latitude: number = (this.currentPoint.latitude + p2.latitude) / 2;
            const longitude: number = (this.currentPoint.longitude + p2.longitude) / 2;
            this.distance = this.getDistance(this.currentPoint, p2);
            this.mapPosition = new Coordinate(latitude, longitude);
          }
        }
        if (this.logistic === LogisticTypeEnum.PRIMARY) {
          this.mapPosition = new Coordinate(
            this.traceListData[this.traceListData.length - 1].latitude,
            this.traceListData[this.traceListData.length - 1].longitude
          );
        }
        this.step = this.step ? this.step : this.logistic != LogisticTypeEnum.PRIMARY ? traceLoad.filter(_ => _?.parada?.id === this.pointId) : null;
        this.loading = false;
        this._cd.detectChanges();
      });
  }

  rad = (x: number) => x * Math.PI / 180;

  getDistance = (p1: RastroViagem, p2: RastroViagem) => {
    var R = 6378137;
    var dLat = this.rad(p2.latitude - p1.latitude);
    var dLong = this.rad(p2.longitude - p1.longitude);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.rad(p1.latitude)) * Math.cos(this.rad(p2.latitude)) *
      Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  };

  checkedOption = (option: TOption) => {
    if (option.key === 6 && option.checked === true) this.checkAndUncheckOptionTrailPovided(3, option.checked);
    if (option.key === 3 && option.checked === true) this.checkAndUncheckOptionTrailPovided(6, option.checked);
    this.changeVisualizationOnMap.next({ type: option.value, checked: option.checked });
  }

  checkAndUncheckOptionTrailPovided = (key: number, checked: boolean) => {
    const index = this.statusOptions.findIndex(option => option.key === key);
    if (index >= 0) {
      const optionFound = this.statusOptions[index];
      optionFound.checked = !checked;
      this.changeVisualizationOnMap.next({ type: optionFound.value, checked: optionFound.checked });
    }
  }

  async buildTrace(steps: any[], trace: RastroViagem[]): Promise<RastroViagem[]> {
    let pointsWithStep: RastroViagem[] = [];
    this.traceListDataProvided.push(this.getStepStart(trace));

    steps.forEach((pointOrdered, i: number) => {
      const index: number = trace.findIndex((point: RastroViagem) => point?.parada?.id === pointOrdered.id);
      if (index > -1) {

        var detalhes = steps.filter((x) => x.id == trace[index].parada.id)[0];

        var anomalias = trace.filter((data) => data.tipoObj == 2)

        var anomaliasParada = anomalias.filter((data) => data.anomalia.idParada == trace[index].parada.id)

        var anomaliaFinalizaForaDoRaio = anomaliasParada.filter((data) => data.anomalia.nome.includes("Entrega fora do raio"))

        var newMap = {
          ...trace[index],
          anomaliaFinalizaForaDoRaio: anomaliaFinalizaForaDoRaio[0],
          detalhes: {
            concluido: detalhes.concluido,
            status: detalhes.status,
            cor: this.monitoramentoService.points.filter((data) => data.id == trace[index].parada.id)[0].color,
            foraDoRaio: anomaliaFinalizaForaDoRaio.length > 0,
          }
        };
        pointsWithStep.push(newMap);

        this.traceListDataProvided.push(trace[index]);
        if (i < steps.length) {
          trace.splice(index, 1);
        }
      }
    });
    this.traceListDataProvided.push(this.getStepFinish(trace));
    let newTrace: RastroViagem[] = pointsWithStep.concat(trace);
    return newTrace;
  }

  getStepStart = (trace: RastroViagem[]) => {
    const index = trace.findIndex(step => step?.parada?.nome?.toLowerCase() === 'início da viagem');
    if (index > -1) {
      return trace[index];
    }
  }

  getStepFinish = (trace: RastroViagem[]) => {
    const index = trace.findIndex(step => step?.parada?.nome?.toLowerCase() === 'fim da viagem');
    if (index > -1) {
      return trace[index];
    }
  }

}
