import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MessageService } from './message.service';
import { LogisticTypeEnum } from '../models/enums/logistic-type.enum';
import { PerfilPermissaoService } from './perfil-permissao.service';

@Injectable()
export abstract class BaseService<T> {

  baseApi = environment.base_api;                            
  baseApiPrimary = environment.base_api_primary;          
  baseApiDistribution = environment.base_api_distribution;  
  baseApiAgroCommodites = environment.base_api_agro_commodities;
  url: string;

  constructor(protected http: HttpClient, 
    protected path: string,
    protected messageService: MessageService, 
    protected perfilPermissaoService: PerfilPermissaoService) {
      
    if (perfilPermissaoService.getCurrentLogisticaRole()?.logistica === LogisticTypeEnum.PRIMARY) {
      this.url = this.baseApiPrimary + `/${path}`;
      return;
    }
    if (perfilPermissaoService.getCurrentLogisticaRole()?.logistica === LogisticTypeEnum.DISTRIBUITION &&
      this.baseApiDistribution.length > 0) {
      this.url = this.baseApiDistribution + `/${path}`;
      return;
    }
    if ((perfilPermissaoService.getCurrentLogisticaRole()?.logistica === LogisticTypeEnum.AGRONOMY ||
      perfilPermissaoService.getCurrentLogisticaRole()?.logistica === LogisticTypeEnum.COMMODITIES) &&
      this.baseApiAgroCommodites.length > 0) {
      this.url = this.baseApiAgroCommodites + `/${path}`;
      return;
    }
    this.url = this.baseApi + `/${path}`;
  }

  header(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return httpOptions;
  }

  find(id: string | number): Observable<T> {
    return this.http.get<T>(this.url + `/${id}`, this.header())
      .pipe(map((resp: any) => {
        return resp as T;
      }),
        catchError(null));
  }

  findAll(): Observable<Array<T>> {
    return this.http.get<Array<T>>(this.url, this.header())
      .pipe(map((resp: any) => {
        let list: Array<T> = [];
        if (resp && resp.length > 0) {
          resp.forEach((item: T) => {
            list.push(item);
          });
        }
        return list;
      }),
        catchError(null));
  }

  delete(id: string | number): Observable<{}> {
    return this.http.delete(this.url + `/${id}`, this.header())
      .pipe(catchError(null));
  }

  post(body: T): Observable<T> {
    return this.http.post<T>(this.url, body, this.header())
      .pipe(map((resp: any) => {
        if (resp && resp.mensagem && resp.tipo && resp.tipo != "0") {
          this.messageService.error(resp.mensagem);
          return null;
        }
        return resp as T[];
      }),
        catchError(null));
  }

  put(body: T): Observable<T> {
    return this.http.put<T>(this.url, body, this.header())
      .pipe(map((resp: any) => {
        if (resp && resp.mensagem && resp.tipo && resp.tipo != "0") {
          this.messageService.error(resp.mensagem);
          return null;
        }
        return resp as T[];
      }),
        catchError(null));
  }


}
