import { PointV2Size } from "../../point-v2.model";
import { ISynopticMapPointModel } from "../synoptic-map-point.interface";
import { SynopticMapPoint } from "../synoptic-map-point.model";


export class SynopticMapPointDistribution extends SynopticMapPoint<SynopticMapPointDistribution>
    implements ISynopticMapPointModel<SynopticMapPointDistribution> {

    constructor(point: any) {
        super(point);
    }

    get color(): string {

        if (this.type === 'in_transit') {
            return 'current';
        }

        if (this.type === 'anomaly' || this.anomalies?.length > 0) {
            return 'delay';
        }

        if (this.unloadingNoteDate !== null && this.unloadingNoteDateFinished === null) {
            return 'current';
        }

        if (this.unloadingNoteDate !== null && this.unloadingNoteDateFinished !== null) {
            return 'ok';
        }

        return 'default';
    }

    get statusColor(): string {
        switch (this.status) {
            case 'A entregar' || 'Pendente':
                return '#8e8f95';
            case 'Finalizada' || 'Finalizada forçada':
                return '#008000';
            case 'Em andamento':
                return '#0000ff';
            case 'Devolução revertida':
                return '#331464';
            case 'Devolução aprovada':
                return '#d22630';
            default:
                return '#8e8f95';
        }
    }

    get current(): boolean {
        if (this.type === 'anomaly') {
            return false;
        }

        if (this.type === 'in_transit') {
            return true;
        }

        if (this.unloadingNoteDate !== null && this.unloadingNoteDateFinished === null) {
            return true;
        }

        if (this.type === 'fim_rota' && this.completed) {
            return true;
        }

        return false;
    }

    get date(): Date {
        let date: Date;

        if (this.expectedUnloadingNoteDate) {
            date = new Date(this.expectedUnloadingNoteDate);
            date.setMilliseconds(0);
            return date;
        }

        return null;
    }

    get dateFinished(): Date {
        let date: Date;

        if (this.expectedUnloadingNoteDateFinished) {
            date = new Date(this.expectedUnloadingNoteDateFinished);
            date.setMilliseconds(0);
            return date;
        }

        return null;
    }

    get urlPoint(): string {
        if (this.type === 'anomaly') {
            return `${this.anomalyUrl}`;
        }
        if (this.type === 'in_transit' && !this.id) {
            return `/anomaly/rota/${this.loadNumber}/tab/0`
        }
        return `/anomaly/rota/${this.loadNumber}/tab/3/point/${this.id}`;
    }

    get urlPointAnomaly(): string {
        if (this.anomalies?.length > 0) {
            const id = this.anomalies.find(anomaly => !anomaly.isDone)?.id
            return `/anomaly/${id}`;
        }
        return '';
    }

    get subtitle(): string {
        if (this.type === 'inicio_rota' || this.type === 'fim_rota') {
            return null;
        }

        if (this.customerCode) {
            return `${this.customerCode} - ${this.name}`;
        }

        return this.name;
    }

    get anomalyName(): string {
        if (this.anomalies === null || this.anomalies?.length === 0) {
            return null;
        }

        return this.anomalies.find(anomaly => !anomaly.isDone)?.name || (this.anomalies.find(anomaly => !anomaly.isDone) as any)?.nome;
    }

    get listAnomaliesName(): string {
        if (this.anomalies === null || this.anomalies?.length === 0) {
            return null;
        }
        return this.anomalies.map(anomaly => anomaly.name).join(', ');
    }

    get anomalyUrl(): string {
        if (this.type === 'anomaly') {
            return `/anomaly/${this.id}/tab/1`;
        }

        if (this.anomalies === null || this.anomalies?.length === 0) {
            return null;
        }
    }

    get size(): PointV2Size {
        if (this.type === 'anomaly') {
            return PointV2Size.ExtraSmaller;
        }

        if (this.anomalies === null || this.anomalies?.length === 0 || this.anomalies?.length <= 9) {
            return this.current ? PointV2Size.Medium : PointV2Size.Smaller;
        }

        return this.current ? PointV2Size.Bigger : PointV2Size.Medium;
    }

    get title(): string {
        switch (this.type.toLowerCase()) {
            case 'in_transit':
                return "Em trânsito";
            case "cliente":
                return "Entrega";
            case "armazem":
                return "Armazém";
            case "wc":
                return "Wc";
            case "posto_fiscal":
                return "Posto Fiscal";
            case "manutencao":
                return "Manutenção";
            case "refeicao":
                return "Refeição";
            case "prf":
                return "PRF";
            case "repouso":
                return "Repouso";
            case "abastecimento":
                return "Abastecimento";
            case "aduana":
                return "Aduana";
            case "transito_descarga":
                return "Em trânsito Descarga";
            case "inicio_rota":
                return "Início da viagem";
            case "fim_rota":
                return "Término da viagem"
        }

        switch (this.typePoint?.toLocaleLowerCase()) {
            case 'coleta':
                return `Carregamento ${this.type}`
            case 'entrega':
                return `Descarga ${this.type}`;
        }
    }
}
