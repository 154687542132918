import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';
import { DiariaStatus, DiariaStatusValue } from 'src/app/enums/diarias/diaria-status.enum';
import { AuthService } from 'src/app/core/services/auth.service';
import { MenuService } from 'src/app/core/services/menu.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { PerfilPermissaoService } from 'src/app/core/services/perfil-permissao.service';
import { DiariaService } from 'src/app/core/services/diaria.service';
import { GDFilterService } from 'src/app/core/services/gd-filter.service';
import { MyFilter } from 'src/app/core/models/my-filter.model';
import { Diarias } from 'src/app/models/diarias.model';
import { SelectOption } from 'src/app/models/common/angular-material-ui/select-option.interface';
import { BaseComponent } from 'src/app/components/shared/base.component';
import { DialogSalvarFiltroComponent } from '../dialog-salvar-filtro/dialog-salvar-filtro.component';
import { LogisticTypeEnum } from '../../../core/models/enums/logistic-type.enum';

export class DialogData {
  useMyFilter: boolean;
  filter: any;
  typeAction: 'edit' | 'custom' | 'my-filter';
}

@Component({
  selector: 'app-dialog-filtro',
  templateUrl: './dialog-filtro.component.html',
  styleUrls: ['./dialog-filtro.component.scss'],
})
export class DialogFiltroComponent extends BaseComponent implements OnInit {

  logistic: string;
  loading = true;
  haveValue = false;
  useMyFilter = false;
  myFilterToEdit: {};
  typeAction: 'edit' | 'custom' | 'my-filter' = 'custom';
  displayIndustryFilter: boolean;
  regionOptions: SelectOption[] = [];
  responsibleAreaOptions: SelectOption[] = [];
  clientsOptions: SelectOption[] = [];
  industriesOptions: SelectOption[] = [];
  form: FormGroup = new FormGroup({
    numeroViagem: new FormControl([]),
    placa: new FormControl([]),
    tempoEmDiaria: new FormControl(''),
    pesoMinimo: new FormControl(''),
    pesoMaximo: new FormControl(''),
    cliente: new FormControl([]),
    motivo: new FormControl([]),
    regiao: new FormControl([]),
    unidadeCalculada: new FormControl([]),
    areaResponsavel: new FormControl([]),
    status: new FormControl([]),
  });

  statusesOptions: SelectOption[] = [];
  statusesOptionsKeys = [
    'PENDING', 'RETURNED', 'WAITING_APPROVAL',
    'APPROVED', 'REVERTED', 'AUTOMATIC_APPROVAL',
    'FINISHED', 'DELIVERED', 'PENDING_TOWER_DECISION',
    'ANALYZED', 'TIME_EXCEEDED', 'AUTOMATIC_APPROVAL_FROM_TIME_EXCEEDED',
    { key: 'WITHIN_DELIVERY_PLANNING', logistics: LogisticTypeEnum.DISTRIBUITION }
  ];

  reasonsOptions = [
    { label: 'Aguardando recebimento', value: 'Aguardando recebimento' },
    { label: 'Cliente - problema com recebimento', value: 'Cliente - problema com recebimento' },
    { label: 'Demora no cliente anterior', value: 'Demora no cliente anterior' },
    { label: 'Antecipação de pedido/envio sem agenda  com alinhamento', value: 'Antecipação de pedido/envio sem agenda  com alinhamento' },
    { label: 'Cliente - problemas com pedido', value: 'Cliente - problemas com pedido' },
    { label: 'Antecipação de pedido/envio sem agenda sem alinhamento', value: 'Antecipação de pedido/envio sem agenda sem alinhamento' },
    { label: 'Atraso na largada', value: 'Atraso na largada' },
    { label: 'Erro de planejamento', value: 'Erro de planejamento' },
    { label: 'Erro de carregamento', value: 'Erro de carregamento' },
    { label: 'Atraso no deslocamento', value: 'Atraso no deslocamento' },
    { label: 'Improdutividade', value: 'Improdutividade' },
    { label: 'Veículo em área de risco', value: 'Veículo em área de risco' },
  ];

  constructor(
    protected authService: AuthService,
    protected router: Router,
    protected menuService: MenuService,
    protected sessionStorageService: SessionStorageService,
    protected perfilPermissaoService: PerfilPermissaoService,
    private dialog: MatDialog,
    private filterService: GDFilterService,
    private diariaService: DiariaService,
    private dialogRef: MatDialogRef<DialogFiltroComponent>,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    super(authService, router, menuService, sessionStorageService, perfilPermissaoService, 'diarias', '#F9FAFB');
  }

  async ngOnInit(): Promise<void> {
    this.logistic = this.perfilPermissaoService.getCurrentLogisticaRole()?.logistica;
    this.createStatusesOptions();
    this.displayIndustryFilter = this.logistic === LogisticTypeEnum.DISTRIBUITION;
    const [clientsList, industriesList, selectOptions] = await Promise.all([
      this.diariaService.getClientsList(),
      this.displayIndustryFilter ? this.diariaService.getIndustriesList() : Promise.resolve([]),
      this.diariaService.getSelectBoxOptions(['regiao', 'areaResponsavel']),
    ]);

    this.clientsOptions = clientsList.map(({ nome, codigo }) => ({ label: nome, value: codigo }));
    this.industriesOptions = industriesList.map(industry => ({ label: industry, value: industry }));
    this.regionOptions = selectOptions.regiao.map(({ label, valor }) => ({ label, value: valor }));
    this.responsibleAreaOptions = selectOptions.areaResponsavel.map(({ label, valor }) => ({ label, value: valor }));

    this.typeAction = this.data.typeAction;
    this.useMyFilter = this.data.useMyFilter;
    this.myFilterToEdit = this.useMyFilter ? this.data.filter : this.myFilterToEdit;

    const initialValue = this.useMyFilter ? this.data.filter : (this.filterService.customFilter || {});
    Object.entries(initialValue).map(([filterKey, filterValue]) => {
      this.form.controls[filterKey].setValue(filterValue);
    });

    this.form.valueChanges.pipe(
      startWith(''),
      map(() => this.haveValue = Object.values(this.form.value).some((value) => Array.isArray(value) ? value.length > 0 : !!value)),
    ).subscribe();

    this.loading = false;
  }

  createStatusesOptions(): void {
    const filteredStatusesOptionsKeys = this.statusesOptionsKeys.filter(
      value => typeof value !== 'string' ? value.logistics.includes(this.logistic) : true
    );
    this.statusesOptions = filteredStatusesOptionsKeys
      .map(value => typeof value === 'string' ? value : value.key)
      .map(key => ({ label: DiariaStatus[key], value: DiariaStatusValue[key] }))
      .sort((optionA, optionB) => optionA.label.localeCompare(optionB.label));
  }

  async applyFilter(): Promise<void> {
    if (!this.data.useMyFilter) {
      this.filterService.customFilter = this.form.value;
    }
    await this.fetchDiariasAndPopulateServiceLists();
    this.dialog.closeAll();
  }

  private async fetchDiariasAndPopulateServiceLists(): Promise<void> {
    const result = await this.diariaService.getAllDiarias(this.form.value);
    this.diariaService.listaDiarias = result.map((element: any) => new Diarias(element));
    this.diariaService.listaDiariasCompleta = result.map((element: any) => new Diarias(element));
  }

  createFilter(): void {
    this.dialogRef.close();
    this.matDialog.open(DialogSalvarFiltroComponent, {
      width: '800px',
      height: 'auto',
      data: {
        screen: 'governanca-diaria',
        objectFilter: this.form.value,
      },
    });
  }

  async editFilter(): Promise<void> {
    this.diariaService.listaDiarias = [];
    this.dialog.closeAll();
    const { id, nomeFiltro, idUsuario } = this.filterService.myFilterSelected;
    await this.filterService.updateFilter(id, nomeFiltro, JSON.stringify(this.form.value), idUsuario);
    this.filterService.myFilterSelected = null;
    this.filterService.myFilters = [];

    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const myFilters = await this.diariaService.getMyFilters(currentUser.email);
    this.filterService.myFilters = myFilters.map((element: any) => new MyFilter(element));
    this.filterService.myFilterSelected = myFilters
      .map((element: any) => new MyFilter(element))
      .filter((element: MyFilter) => element.filtroPadrao)[0];

    await this.fetchDiariasAndPopulateServiceLists();
  }

  close(): void {
    this.dialog.closeAll();
  }

  clearFilter(): void {
    Object.keys(this.form.controls).map((filterKey) => {
      const filterControl = this.form.get(filterKey);
      filterControl.setValue(Array.isArray(filterControl.value) ? [] : '');
    });
  }
}
