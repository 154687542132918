import { LogisticTypeEnum } from 'src/app/core/models/enums/logistic-type.enum';
import { TypeOperation, TypeOperationCommoditiesEnum } from 'src/app/core/models/enums/type-operation.enum';
import { Anomaly } from '../anomaly.model';
import { PointV2 } from '../point-v2.model';
import { SynopticMapPointAgroSlaughterAnimal } from './agro/synoptic-map-point-agro-slaughter-animal.model';
import { SynopticMapPointAgro } from './agro/synoptic-map-point-agro.model';
import { SynopticMapPointCommodities } from './commodities/synoptic-map-point-commodities.model';
import { SynopticMapPointDistribution } from './distribution/synoptic-map-point-distribution.model';
import { SynopticMapPointPrimary } from './primary/synoptic-map-point-primary.model';
import { SynopticMapThreePoint } from './synoptic-map-three-point.model';

export class SynopticMap<Point> {

    #logistic: string;

    #loadStatus: string;

    #tipoOperacao: TypeOperationCommoditiesEnum | string;

    #loadNumber: string;

    #originalPoints: Point[];

    #points: Point[];

    #pointsDescending: Point[];

    #threePoints: SynopticMapThreePoint[] = [];

    #pointsMap: PointV2[];

    #anomalies: Anomaly[];

    #isMonitoringContinuos;

    constructor({
        logistica,
        tipoOperacao,
        numeroViagem,
        status,
        paradas,
        anomalias,
        monitoramentoContinuo
    }) {
        this.#logistic = logistica;
        this.#loadStatus = status;
        this.#loadNumber = numeroViagem;
        this.#tipoOperacao = tipoOperacao;
        this.#isMonitoringContinuos = !!monitoramentoContinuo;
        let deliveryType = false;
        if (tipoOperacao == TypeOperation.OVOS_CARRINHOS) {
            const index = paradas.findIndex((parada: any) => parada.tipoParada == 'entrega');
            if (index > 0) {
                deliveryType = true;
            }
        }
        this.#originalPoints = paradas.map(parada => {
            parada.numeroViagem = numeroViagem;

            if (logistica !== LogisticTypeEnum.DISTRIBUITION) {
                parada.anomalias = anomalias
                    .filter(anomalia => anomalia.idParada === parada.id)
                    .map(anomalia => new Anomaly(anomalia));
            }
            switch (logistica) {
                case LogisticTypeEnum.AGRONOMY:
                    return this.#tipoOperacao == 'Ração' ?
                        new SynopticMapPointAgro(parada) :
                        new SynopticMapPointAgroSlaughterAnimal({ ...parada, tipoOperacao, status, deliveryType });
                case LogisticTypeEnum.COMMODITIES:
                    return new SynopticMapPointCommodities(parada);
                case LogisticTypeEnum.PRIMARY:
                    return new SynopticMapPointPrimary(parada);
                case LogisticTypeEnum.DISTRIBUITION:
                    return parada;
                default:
                    console.error(`Logística "${logistica}" não implementada`);
                    return parada;
            }
        });

        if (logistica === LogisticTypeEnum.DISTRIBUITION) {
            this.#anomalies = anomalias.sort((a: any, b: any) => a.creationAt - b.creationAt);
            return;
        }

        this.#anomalies = anomalias
            .filter((anomalia: any) => anomalia.idParada === null)
            .map((anomalia: any) => new Anomaly(anomalia))
            .sort((a: any, b: any) => a.creationAt - b.creationAt);
    }

    get logistic(): string {
        return this.#logistic;
    }

    get tipoOperacao(): TypeOperationCommoditiesEnum | string {
        return this.#tipoOperacao;
    }

    get loadStatus(): string {
        return this.#loadStatus;
    }

    get loadNumber(): string {
        return this.#loadNumber;
    }

    get originalPoints(): Point[] {
        return this.#originalPoints;
    }

    get points(): Point[] {
        return this.#points;
    }

    set points(newPoints: Point[]) {
        this.#points = newPoints;
    }

    get pointsDescending(): Point[] {
        return this.#pointsDescending;
    }

    set pointsDescending(newPoints: Point[]) {
        this.#pointsDescending = newPoints;
    }

    get threePoints(): SynopticMapThreePoint[] {
        return this.#threePoints;
    }

    set threePoints(newPoints: SynopticMapThreePoint[]) {
        this.#threePoints = newPoints;
    }

    get pointsMap(): PointV2[] {
        return this.#pointsMap;
    }

    set pointsMap(points: PointV2[]) {
        this.#pointsMap = points;
    }

    get anomalies(): Anomaly[] {
        return this.#anomalies;
    }

    get isMonitoringContinuos(): boolean {
        return this.#isMonitoringContinuos;
    }
}
