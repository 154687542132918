export enum DiariaStatus {
  PENDING = 'Pendente',
  WAITING_APPROVAL = 'Em Aprovação',
  APPROVED = 'Aprovada',
  DELIVERED = 'Entregue',
  RETURNED = 'Devolução',
  ANALYZED = 'Analisada',
  TIME_EXCEEDED = 'Tempo Excedido',
  PENDING_TOWER_DECISION = 'Pendente Decisão Torre',
  REVERTED = 'Revertida',
  FINISHED = 'Finalizada',
  AUTOMATIC_APPROVAL = 'Aprovação automática',
  AUTOMATIC_APPROVAL_FROM_TIME_EXCEEDED = 'Aprovação automática por tempo excedido',
  REPROVED = 'Reprovada',
  WITHIN_DELIVERY_PLANNING = 'Dentro do planejamento de entrega',
}

export enum DiariaStatusValue {
  PENDING =  0,
  WAITING_APPROVAL = 1,
  APPROVED = 2,
  DELIVERED = 3,
  RETURNED = 4,
  ANALYZED = 5,
  TIME_EXCEEDED = 6,
  PENDING_TOWER_DECISION = 7,
  REVERTED = 8,
  FINISHED = 9,
  AUTOMATIC_APPROVAL = 10,
  AUTOMATIC_APPROVAL_FROM_TIME_EXCEEDED = 11,
  WITHIN_DELIVERY_PLANNING = 12,
}
