import { Injectable } from "@angular/core";
import { IconsPrimary } from "../models/enums/icons-primary";
import { LogisticTypeEnum } from "../models/enums/logistic-type.enum";
import { StateColorEnum } from "../models/enums/state-color.enum";
import { RastroViagem } from "../models/rastro-viagem.model";
import { Coordinate } from "src/app/models/google-maps/coordinate.model";
import { Anomaly } from "src/app/models/anomaly.model";
import { BehaviorSubject } from "rxjs";


export interface MarkerPosition {
    location: {
        lat: number,
        lng: number,
        icon: string,
        radius: number,
        pointsPolygon: Coordinate[],
        label?: string,
        descricao?: string
    },
    isAnomaly?: boolean,
    isMarkerPosition?: boolean,
    isVisited?: boolean,
    isJustified?: boolean,
    isUnit?: boolean
}

@Injectable({
    providedIn: 'root'
})
export class GmapTravelService {


    iconCurrentPoint = "../../../../assets/icons/maps-pointer/pin_current_.svg";
    iconCurrentPointDistribution = "../../../../assets/icons/maps-pointer/pin_current_distribution.svg";
    iconStepOkPoint = "../../../../assets/icons/maps-pointer/sucess_marker.svg";
    iconStepErrorPoint = "../../../../assets/icons/maps-pointer/anomaly_marker.svg";
    iconStepSelectedPointOk = "../../../../assets/icons/maps-pointer/selected_marker_ok.svg";
    iconSetepSelectedPointError = "../../../../assets/icons/maps-pointer/selected_marker_error.svg"
    iconStepOriginalAddress = "../../../../assets/icons/maps-pointer/SpotlightMarker.svg"
    iconStartOutSideRange = "../../../../assets/icons/maps-pointer/pin_house_red.svg";
    iconWouldStart = "../../../../assets/icons/maps-pointer/pin_house_blue.svg";
    iconNotVisited = "../../../../assets/icons/maps-pointer/pin_house_gray.svg";

    constructor() { }
    pointerMarker = [];

    centerMap: BehaviorSubject<any> = new BehaviorSubject<any>(null);


    setCenterMap(lat: number, lng: number) {
        this.centerMap.next({ lat: lat, lng: lng });
    }

    pointsMap: any[] = [];
    //Método responsável por buscar as direções e marcadorespara uma rota (Primária)
    getDirectionsAndMarker(rastro, step) {

        var waypoints = [];
        var direction = { waypoints: [], origin: {}, destination: {}, markerOptions: {} };
        var directions = [];
        var markers = [];
        return new Promise<any>((resolve) => {
            var rastroFiltred = Array.isArray(rastro) ? rastro.filter(x => x.dataHora) : null;
            var trailMarker = Array.isArray(rastro) ? rastro.filter(x => x.dataHora && x.tipoObj === 2) : null;

            var pontosRastreamento = rastroFiltred.filter(x => x.tipoObj === 0);
            if (rastroFiltred && rastroFiltred.length > 0) {
                trailMarker.map(x => { markers.push({ location: { lat: x.latitude, lng: x.longitude, icon: this.iconStepErrorPoint, radius: x?.radius || 0 } }) })
                for (let index = 0; index < rastroFiltred.length; index++) {
                    const ponto = rastroFiltred[index];
                    waypoints.push({ location: { lat: ponto.latitude, lng: ponto.longitude, radius: 0, pointsPolygon: [] }, stopover: false });

                    if (ponto.tipoObj === 1) {
                        direction.waypoints = waypoints;
                        direction.origin = { lat: waypoints[0].location.lat, lng: waypoints[0].location.lng }
                        direction.destination = { lat: ponto.latitude, lng: ponto.longitude, id: ponto.parada.id };
                        directions.push(direction);
                        if (directions.length === 1) {
                            direction.markerOptions = {
                                destination: {
                                    draggable: true,
                                    icon: step?.id === ponto.parada?.id ? this.iconStepSelectedPointOk : this.iconStepOkPoint,
                                    title: ponto.parada.nome
                                }, origin: { draggable: true, title: "Em trânsito para carregamento", icon: step?.type === "transito_carregamento" ? this.iconStepSelectedPointOk : this.iconStepOkPoint }
                            };
                        }
                        else {
                            direction.markerOptions = {
                                destination: {
                                    draggable: true,
                                    icon: step?.id === ponto.parada?.id ? this.iconStepSelectedPointOk : this.iconStepOkPoint,
                                    title: ponto.parada.nome
                                }, origin: { draggable: true, opacity: 0.0 }
                            };
                        }
                        direction = { waypoints: [], origin: {}, destination: {}, markerOptions: {} };
                        waypoints = [];

                    }
                }
                if (waypoints.length > 0) {
                    var lastPoint = pontosRastreamento[pontosRastreamento.length - 1];
                    direction.waypoints = waypoints;
                    direction.origin = { lat: waypoints[0].location.lat, lng: waypoints[0].location.lng }
                    direction.destination = { lat: waypoints[waypoints.length - 1].location.lat, lng: waypoints[waypoints.length - 1].location.lng };

                    direction.markerOptions = {
                        destination: {
                            draggable: true,
                            icon: this.iconCurrentPoint,
                            title: lastPoint && lastPoint?.estaParado ? "Veículo parado" : "Em trânsito para carregamento"
                        }, origin: directions.length === 0 ? { draggable: true, title: "Em trânsito para carregamento", icon: this.iconStepOkPoint } : { draggable: true, opacity: 0.0 }
                    };
                    directions.push(direction);
                }
                this.pointsMap = markers;
                resolve({ directions: directions, markers: markers });
            }
            if (!Array.isArray(rastro) && rastro.endereco && rastro.endereco.longitude && rastro.endereco.latitude) {
                markers.push({
                    location: {
                        lat: rastro.endereco.latitude,
                        lng: rastro.endereco.longitude,
                        icon: this.iconCurrentPoint,
                        radius: 0,
                        pointsPolygon: []
                    }
                });
                this.pointsMap = markers;
                resolve({ directions: directions, markers: markers });
            }
        })
    }

    getDirectionsAndMarker1(rastro, markersPosition: any[], step, points, pointId, currentPosition: RastroViagem = null, logistic, anomalias = []) {
        var waypoints = [];
        var directions = [];
        var directionsMarker = [];
        var markers: any[] = [];

        this.iconCurrentPoint = (logistic === LogisticTypeEnum.DISTRIBUITION) ? this.iconCurrentPointDistribution : this.iconCurrentPoint;

        return new Promise<any>((resolve) => {

            if (rastro?.length == 0 && points?.length > 0) {
                for (let p of points) {

                    let icon = this.iconCurrentPoint;
                    if (p.isOriginalAddress)
                        icon = this.iconStepOriginalAddress;

                    markers.push({
                        location: {
                            lat: p.latitude,
                            lng: p.longitude,
                            icon,
                            label: p?.label,
                            descricao: '',
                            radius: 0,
                            pointsPolygon: []
                        }
                    });
                }
                resolve({ directions: directions, markers: markers });
                return;
            }
            if (rastro.length > 1) {
                rastro = this.orderPositionStartAndFinish(rastro);
            }
            var rastroFiltred = Array.isArray(rastro) ? rastro.filter(x => x?.dataHora) : null;
            if (logistic === LogisticTypeEnum.PRIMARY || logistic === LogisticTypeEnum.DISTRIBUITION) {
                var trailMarker = Array.isArray(rastro) ? rastro.filter(x =>
                    x?.dataHora && x.tipoObj !== 0 || x?.parada
                ) : null;
            } else {
                var trailMarker = Array.isArray(rastro) ? rastro.filter(x => x.dataHora && x.tipoObj !== 0) : null;
            }
            const drawWaypoints = Array.isArray(rastro)
                ? rastro.filter(x =>
                    x?.dataHora ||
                    x?.parada?.nome.toLowerCase() == 'início viagem' ||
                    x?.parada?.nome.toLowerCase() == 'fim da viagem')
                : null;
            var inicioForaDesposito = Array.isArray(rastro) ? rastro.filter(x => x?.tipoObj === 3).length > 0 : false;
            if (Array.isArray(rastro)) {
                if (pointId) {
                    if (!step) {
                        step = {};
                        step.displayCurrentLocation = true;
                    }
                    step.id = pointId;
                }

                let position = 1;
                trailMarker.map(x => {

                    if (pointId && (step?.id == x.anomalia?.idParada))
                        step.color = StateColorEnum.DANGER;

                    if (markersPosition && markersPosition.filter(point => point.id === x?.parada?.id).length > 0) {
                        var colorMap = {
                            '#D22630': StateColorEnum.DANGER,
                            '#808080': StateColorEnum.NOT_VISITED,
                            '#76BB79': StateColorEnum.OK,
                            '#003087': StateColorEnum.CURRENT,
                            '#B8BAC2': StateColorEnum.DEFAULT
                        }

                        var color = x.detalhes ? colorMap[x.detalhes.cor] : StateColorEnum.DEFAULT;

                        var icon =
                            this.getIconPosition(position, color)

                        if (x.detalhes) {
                            if (x.detalhes["concluido"] && x.detalhes["status"] == "Finalizada") {
                                icon = this.getIconPosition(position, StateColorEnum.OK)
                            }

                            // if(x.detalhes["foraDoRaio"]) {
                            //     icon = this.getIconPosition(position, StateColorEnum.DANGER)
                            // }
                        }

                        markers.push({
                            isAnomaly: false,
                            isMarkerPosition: true,
                            isVisited: false,
                            isJustified: false,
                            detalhes: x.detalhes,
                            location: {
                                lat: x.latitude, lng: x.longitude,
                                //icon: icon,
                                icon: this.getIconPosition(position, StateColorEnum.DEFAULT),
                                label: x.parada?.nome ? x.parada?.nome : x.anomalia?.nome,
                                radius: x?.radius || 0,
                                descricao: null,
                                pointsPolygon: x.pointsPolygon || []
                            }
                        });
                        if (x.anomaliaFinalizaForaDoRaio) {

                            markers.push({
                                isAnomaly: true,
                                isMarkerPosition: false,
                                isVisited: true,
                                isJustified: false,
                                //isUnit: typePoint.isUnit,
                                location: {
                                    lat: x.anomaliaFinalizaForaDoRaio.latitude, lng: x.anomaliaFinalizaForaDoRaio.longitude,
                                    icon: this.getIconPosition(position, StateColorEnum.DANGER),
                                    label: x.anomaliaFinalizaForaDoRaio.anomalia?.nome,
                                    radius: 0,
                                    descricao: '',
                                    pointsPolygon: []
                                }
                            })


                            /* markers.push({
                                isAnomaly: false,
                                isMarkerPosition: false,
                                isVisited: false,
                                isJustified: false,
                                isFinished: true,
                                detalhes: x.detalhes,
                                location: {
                                    lat: x.latitude, lng: x.longitude,
                                    //icon: icon,
                                    icon: this.getIconPosition(position, StateColorEnum.OK),
                                    label: x.parada?.nome ? x.parada?.nome : x.anomalia?.nome,
                                    radius: x?.radius || 0,
                                    descricao: 'Entrega realizada',
                                    pointsPolygon: x.pointsPolygon || []
                                }
                            }); */

                        } else {
                            markers.push({
                                isAnomaly: false,
                                isMarkerPosition: false,
                                isVisited: false,
                                isJustified: false,
                                isFinished: true,
                                detalhes: x.detalhes,
                                location: {
                                    lat: x.latitude, lng: x.longitude,
                                    icon: this.getIconPosition(
                                        position,
                                        x?.detalhes?.status.toLowerCase() === 'finalizada' ? StateColorEnum.OK : StateColorEnum.DEFAULT),
                                    label: x.parada?.nome ? x.parada?.nome : x.anomalia?.nome,
                                    radius: x?.radius || 0,
                                    descricao: x?.detalhes?.status.toLowerCase() === 'finalizada' ? 'Entrega realizada' : x?.detalhes?.status,
                                    pointsPolygon: x.pointsPolygon || []
                                }
                            });
                        }

                        if (x?.latitudeRealizada && x.longitudeRealizada) {
                            let color = StateColorEnum.OK;
                            if (x.anomalia) color = StateColorEnum.DANGER;
                            markers.push({
                                isAnomaly: false,
                                isMarkerPosition: true,
                                isVisited: (x.latitudeRealizada && x.longitudeRealizada),
                                isJustified: false,
                                location: {
                                    lat: x.latitudeRealizada, lng: x.longitudeRealizada,
                                    icon: this.getIconPosition(position, color),
                                    label: x.parada?.nome ? x.parada?.nome : x.anomalia?.nome,
                                    radius: x?.radius || 0,
                                    descricao: '',
                                    pointsPolygon: x?.pointsPolygon || []
                                }
                            });
                        }
                        position++;
                    } else {
                        const typePoint: any = this.getTypeStep(x, step, points, inicioForaDesposito, logistic);
                        var an = anomalias.filter((element) => element['anomalia']['endereco']['latitude'] == x['latitude'] && element['anomalia']['endereco']['longitude'] == x['longitude'] && element['anomalia']['tipo'] == 0)
                        markers.push({
                            isAnomaly: !!x.anomalia,
                            isMarkerPosition: false,
                            isVisited: !!x.dataHora,
                            isJustified: x.tipoObj === 1,
                            isUnit: typePoint.isUnit,
                            location: {
                                lat: x.latitude, lng: x.longitude,
                                icon: typePoint.icon,
                                label: x.parada?.nome ? x.parada?.nome : x.anomalia?.nome,
                                radius: x?.radius || 0,
                                descricao: an.length > 0 ? an[0]['anomalia']['descricao'] : null,
                                pointsPolygon: x?.pointsPolygon || []
                            }
                        })
                    }
                });
                let markersToDirection = markers.filter(marker => marker.isMarkerPosition);
                if (drawWaypoints.length > 0) {
                    markersToDirection.unshift({
                        isMarkerPosition: true,
                        location: {
                            lat: drawWaypoints[0].latitude, lng: drawWaypoints[0].longitude,
                            icon: null,
                            label: drawWaypoints[0].parada?.nome ? drawWaypoints[0].parada?.nome : drawWaypoints[0].anomalia?.nome,
                            radius: drawWaypoints[0]?.radius || 0,
                            descricao: '',
                            pointsPolygon: drawWaypoints[0]?.pointsPolygon || []
                        }
                    });
                }
                markersToDirection.push(markers[markers.length - 1]);
                markersToDirection.forEach((marker, index) => {
                    if (index < markersToDirection.length - 1) {
                        // if (marker.location) {
                        //     if (marker.location.lat && marker.location.lng) {
                        directionsMarker.push({
                            origin: { lat: marker.location.lat, lng: marker.location.lng },
                            // destination: { lat: markersToDirection[index + 1].location.lat, lng: markersToDirection[index + 1].location.lng },
                        })
                        // }

                        // }

                    }
                });
                for (let index = 0; index < drawWaypoints.length; index++) {
                    const ponto = drawWaypoints[index];

                    if (inicioForaDesposito === true && ponto.parada?.nome === 'Início da Viagem')
                        continue;

                    if (ponto.latitude == 0 || ponto.longitude === 0 || ponto.latitude == null || ponto.longitude == null)
                        continue;

                    waypoints.push({ location: { lat: ponto.latitude, lng: ponto.longitude }, stopover: false });
                    if (waypoints.length >= 23) {
                        var direction = { waypoints: [], origin: {}, destination: {}, markerOptions: {} };
                        direction.waypoints = waypoints;
                        direction.origin = { lat: waypoints[0].location.lat, lng: waypoints[0].location.lng }
                        direction.destination = { lat: waypoints[waypoints.length - 1].location.lat, lng: waypoints[waypoints.length - 1].location.lng };
                        directions.push(direction);
                        waypoints = [];
                    }

                }

                if (rastroFiltred && rastroFiltred.length > 0) {
                    //marcar ponto corrente
                    if (step?.isRouteActive || step?.type == "fim_rota") {
                        markers.push({
                            location: {
                                lat: rastroFiltred[rastroFiltred.length - 1].latitude, lng: rastroFiltred[rastroFiltred.length - 1].longitude,
                                icon: step?.isRouteActive ? this.iconCurrentPoint : (step?.type === "fim_rota" ?
                                    (step?.color === StateColorEnum.DANGER ? this.iconSetepSelectedPointError : this.iconStepSelectedPointOk) :
                                    (points && this.getPointFimRota(points).color === StateColorEnum.DANGER ? this.iconStepErrorPoint : this.iconStepOkPoint)),
                                label: step?.isRouteActive ? "" : "Término da viagem",
                                descricao: '',
                                radius: 0,
                                pointsPolygon: []
                            }
                        })
                        waypoints.push({ location: { lat: rastroFiltred[rastroFiltred.length - 1].latitude, lng: rastroFiltred[rastroFiltred.length - 1].longitude }, stopover: false });
                    }
                }

                if (waypoints.length > 0) {
                    var direction = { waypoints: [], origin: {}, destination: {}, markerOptions: {} };
                    direction.waypoints = waypoints;
                    direction.origin = { lat: waypoints[0].location.lat, lng: waypoints[0].location.lng }
                    direction.destination = { lat: waypoints[waypoints.length - 1].location.lat, lng: waypoints[waypoints.length - 1].location.lng };
                    directions.push(direction);
                }
                if (currentPosition !== null) {
                    const newCurrentPosition: MarkerPosition = {
                        location:
                        {
                            lat: currentPosition.latitude,
                            lng: currentPosition.longitude,
                            icon: this.iconCurrentPoint,
                            label: currentPosition?.description || 'Posição atual do veículo',
                            descricao: '',
                            radius: 0,
                            pointsPolygon: []
                        }
                    }
                    markers.push(newCurrentPosition);
                }
                if (rastroFiltred.length > 0 && currentPosition == null) {
                    markers.push({
                        location:
                        {
                            lat: rastroFiltred[rastroFiltred.length - 1].latitude,
                            lng: rastroFiltred[rastroFiltred.length - 1].longitude,
                            icon: this.iconCurrentPoint,
                            label: currentPosition?.description || 'Posição atual do veículo',
                            descricao: '',
                            radius: 0,
                            pointsPolygon: []
                        }
                    });
                }
                markers = this.markersInSamePosition(markers);
                resolve({ directions, markers, directionsMarker });
            } else {
                markers.push({
                    location:
                    {
                        lat: rastro.endereco.latitude,
                        lng: rastro.endereco.longitude,
                        icon: this.iconCurrentPoint,
                        label: currentPosition?.description || 'Posição atual do veículo',
                        radius: 0,
                        pointsPolygon: []
                    }
                });
                resolve({ directions, markers, directionsMarker });
            }
        })
    }

    markersInSamePosition(markers: any[]) {
        let markersMap = new Map();
        markers.forEach((item: any, index: number) => {
            let size = item.location.lat < 0 ? 8 : 7;
            const key = `${item.location.lat.toString().substring(0, size)}${item.location.lng.toString().substring(0, size)}`
            if (markersMap.has(key)) {
                markers[index].location.lng = markers[index].location.lng + 0.00002;
            } else {
                markersMap.set(key, item);
            }
        })
        return markers;
    }

    getTypeStep(x, step, points, inicioForaDesposito, logistic): { icon: string, isUnit: boolean } {
        if (logistic == LogisticTypeEnum.PRIMARY) {
            const point = points.filter(a => a.id === x?.parada?.id);
            if (point.length == 0) return { icon: this.iconStepOkPoint, isUnit: false };
            switch (point[0].icon) {
                case 'cd':
                case 'origem':
                case 'carregamento':
                    return { icon: IconsPrimary.ICON_CD, isUnit: true };
                case 'ponto_fiscal':
                    return { icon: IconsPrimary.ICON_FISCAL, isUnit: true };
                case 'client':
                    return { icon: IconsPrimary.ICON_CLIENT, isUnit: true };
                case 'troca_nf':
                    return { icon: IconsPrimary.ICON_NF, isUnit: true };
                case 'port':
                    return { icon: IconsPrimary.ICON_PROVIDER, isUnit: true };
                case 'up':
                    return { icon: IconsPrimary.ICON_UP, isUnit: true };
                case 'supply':
                    return { icon: IconsPrimary.ICON_SUPPLY, isUnit: true };
                case 'wc':
                    return { icon: IconsPrimary.ICON_WC, isUnit: true };
                case 'rest':
                    return { icon: IconsPrimary.ICON_REST, isUnit: true };
                case 'fiscal':
                    return { icon: IconsPrimary.ICON_FISCAL, isUnit: true };
                case 'prf':
                    return { icon: IconsPrimary.ICON_PRF, isUnit: true };
                case 'meal':
                    return { icon: IconsPrimary.ICON_MEAL, isUnit: true };
                case 'warehouse':
                    return { icon: IconsPrimary.ICON_WAREHOUSE, isUnit: true };
                case 'maintenance':
                    return { icon: IconsPrimary.ICON_MAINTENANCE, isUnit: true };
                case 'home':
                    return { icon: IconsPrimary.ICON_HOME, isUnit: true };
                case 'stop':
                    return { icon: IconsPrimary.ICON_STOP, isUnit: true };
            }
        }

        if (x.tipoObj === 2 && (step?.id != x.anomalia?.idParada))
            return { icon: this.iconStepErrorPoint, isUnit: false };

        if (x.tipoObj === 3)
            return { icon: this.iconStartOutSideRange, isUnit: false };

        if (x.tipoObj === 4) {
            return { icon: this.iconCurrentPoint, isUnit: false };
        }

        if (x.parada?.nome === 'Início da Viagem')
            if (inicioForaDesposito)
                return { icon: this.iconStartOutSideRange, isUnit: false };
            else
                return { icon: this.iconWouldStart, isUnit: false };

        if (step?.id === x.parada?.id || step?.id === x.anomalia?.idParada)
            return (
                step?.color === StateColorEnum.DANGER
                    ? { icon: this.iconSetepSelectedPointError, isUnit: false }
                    : { icon: this.iconWouldStart, isUnit: false });

        return (x.parada && points?.length > 0 && this.getPoint(points, x.parada?.id).color === StateColorEnum.DANGER
            ? { icon: this.iconStepErrorPoint, isUnit: false }
            : { icon: this.iconStepOkPoint, isUnit: false });
    }


    getPoint(points, paradaID) {
        var point = points.filter(x => x.id === paradaID)[0];
        return point;
    }

    getPointFimRota(points) {
        var point = points.filter(x => x.icon === 'end')[0];
        return point;
    }
    getDirectionsContinuousMonitoring(rastro, step) {
        var waypoints = [];
        var direction = { waypoints: [], origin: {}, destination: {}, markerOptions: {} };
        var directions = [];

        return new Promise<any>((resolve) => {
            var rastroFiltred = Array.isArray(rastro) ? rastro.filter(x => x.dataHora) : null;

            var pontosRastreamento = rastroFiltred.filter(x => x.tipoObj === 0);
            if (pontosRastreamento && pontosRastreamento.length > 0) {
                var lastPoint = pontosRastreamento[pontosRastreamento.length - 1];
                var firstPoint = pontosRastreamento[0];

                direction.waypoints = waypoints;
                direction.origin = { lat: firstPoint.latitude, lng: firstPoint.longitude }
                direction.destination = { lat: lastPoint.latitude, lng: lastPoint.longitude };
                direction.markerOptions = {
                    destination: !step.isRouteActive ? { draggable: true, opacity: 0.0 } : {
                        draggable: true,
                        icon: this.iconCurrentPoint,
                        title: rastroFiltred && lastPoint?.estaParado ? "Veículo parado" : "Em trânsito"
                    }, origin: { draggable: true, opacity: 0.0 }
                };
                directions.push(direction);
            }
            resolve({ directions: directions, markers: [] });

        });

    }

    orderPositionStartAndFinish(trail: RastroViagem[]): RastroViagem[] {
        const startIndex = trail.findIndex(_ => _?.parada?.nome?.toLowerCase() == 'início da viagem');
        if (startIndex > -1) {
            const startTrail = trail[startIndex];
            trail.splice(startIndex, 1);
            trail.unshift(startTrail);
        }
        const finishIndex = trail.findIndex(_ => _?.parada?.nome?.toLowerCase() == 'fim da viagem');
        if (finishIndex > -1) {
            const finishTrail = trail[finishIndex];
            trail.splice(finishIndex, 1);
            trail.push(finishTrail);
        }
        return trail;
    }

    getIconPosition(position: number, color: string) {
        const svg = `<svg width="38" height="55" viewBox="0 0 48 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7526_133)">
            <g filter="url(#filter0_f_7526_133)">
            <path d="M24.75 65C28.4779 65 31.5 63.3081 31.5 61.221C31.5 59.1338 28.4779 57.4419 24.75 57.4419C21.0221 57.4419 18 59.1338 18 61.221C18 63.3081 21.0221 65 24.75 65Z" fill="black" fill-opacity="0.12"/>
            </g>
            <g filter="url(#filter1_d_7526_133)">
            <mask id="mask0_7526_133" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="3" y="0" width="43" height="63">
            <path d="M45.8848 0.897583H3.88477V62.8743H45.8848V0.897583Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.4499 60.7942C25.2893 60.7912 25.9686 60.1045 25.9686 59.2578C25.9686 48.5467 31.9148 41.984 36.3668 37.0704C37.6622 35.6407 38.8311 34.3506 39.6902 33.1385H39.6893C42.0821 29.9286 43.5 25.9385 43.5 21.6145C43.5 11.0077 34.9676 2.40918 24.4424 2.40918C13.9172 2.40918 5.38477 11.0077 5.38477 21.6145C5.38477 26.0433 6.87226 30.1218 9.37123 33.3706C10.2119 34.5152 11.314 35.7316 12.5271 37.0704H12.5271C16.9791 41.984 22.9253 48.5467 22.9253 59.2578C22.9253 60.1045 23.6048 60.7912 24.444 60.7942" fill="black"/>
            </mask>
            <g mask="url(#mask0_7526_133)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.4499 60.7942C25.2893 60.7912 25.9686 60.1045 25.9686 59.2578C25.9686 48.5467 31.9148 41.984 36.3668 37.0704C37.6622 35.6407 38.8311 34.3506 39.6902 33.1385C42.083 29.9286 43.5 25.9385 43.5 21.6145C43.5 11.0077 34.9676 2.40918 24.4424 2.40918C13.9172 2.40918 5.38477 11.0077 5.38477 21.6145C5.38477 26.0433 6.87226 30.1218 9.37123 33.3706C10.2119 34.5152 11.314 35.7316 12.5271 37.0704C16.9791 41.984 22.9253 48.5467 22.9253 59.2578C22.9253 60.1045 23.6048 60.7912 24.444 60.7942" fill="${color}"/>
            <path d="M24.444 61.2421H24.4499V60.3463H24.444V61.2421ZM36.3668 37.0705L37.0232 37.6745L36.3668 37.0705ZM39.6902 33.1386L40.4135 33.6593L41.4174 32.2428H39.6902V33.1386ZM39.6893 33.1386L38.9786 32.6006L37.9098 34.0343H39.6893V33.1386ZM9.37126 33.3706L10.0859 32.8376L10.0738 32.8219L9.37126 33.3706ZM12.5271 37.0705L13.217 36.5056L13.2008 36.4855L13.1836 36.4664L12.5271 37.0705ZM12.5272 37.0705L11.8373 37.6353L11.8535 37.6555L11.8708 37.6745L12.5272 37.0705ZM24.4532 61.69C25.782 61.685 26.8575 60.598 26.8575 59.2578H25.0799C25.0799 59.6109 24.7965 59.8972 24.4466 59.8985L24.4532 61.69ZM26.8575 59.2578C26.8575 48.9248 32.5676 42.5921 37.0232 37.6745L35.7104 36.4664C31.2621 41.376 25.0799 48.1687 25.0799 59.2578H26.8575ZM37.0232 37.6745C38.3075 36.257 39.5169 34.9242 40.4135 33.6593L38.9669 32.618C38.1452 33.7771 37.0169 35.0245 35.7104 36.4664L37.0232 37.6745ZM39.6902 32.2428H39.6893V34.0343H39.6902V32.2428ZM40.4 33.6765C42.9045 30.3168 44.3889 26.1391 44.3889 21.6145H42.6111C42.6111 25.7378 41.2598 29.5404 38.9786 32.6006L40.4 33.6765ZM44.3889 21.6145C44.3889 10.513 35.4585 1.51343 24.4424 1.51343V3.30498C34.4768 3.30498 42.6111 11.5025 42.6111 21.6145H44.3889ZM24.4424 1.51343C13.4263 1.51343 4.49591 10.513 4.49591 21.6145H6.2737C6.2737 11.5025 14.4081 3.30498 24.4424 3.30498V1.51343ZM4.49591 21.6145C4.49591 26.2489 6.05323 30.5192 8.66872 33.9195L10.0738 32.8219C7.69135 29.7245 6.2737 25.8377 6.2737 21.6145H4.49591ZM8.65679 33.9036C9.53029 35.0928 10.6668 36.3458 11.8707 37.6745L13.1836 36.4664C11.9613 35.1174 10.8935 33.9375 10.0857 32.8377L8.65679 33.9036ZM11.8373 37.6353H11.8373L13.217 36.5056H13.217L11.8373 37.6353ZM11.8708 37.6745C16.3263 42.5921 22.0364 48.9248 22.0364 59.2578H23.8142C23.8142 48.1687 17.6319 41.376 13.1836 36.4664L11.8708 37.6745ZM22.0364 59.2578C22.0364 60.598 23.112 61.685 24.4407 61.69L24.4473 59.8985C24.0975 59.8972 23.8142 59.6109 23.8142 59.2578H22.0364Z" fill="url(#paint0_linear_7526_133)"/>
            </g>
            </g>
            <path d="M25.6193 15.4545V30H23.858V17.3011H23.7727L20.2216 19.6591V17.8693L23.858 15.4545H25.6193Z" fill="transparent"/>
            <g transform="translate(25, 30)">
                <text text-anchor="middle" style="stroke: #FFFFFF; font-size: 22px;">
                ${position}
                </text>
            </g>
            </g>
            <defs>
            <filter id="filter0_f_7526_133" x="16.8148" y="56.2567" width="15.8704" height="9.92848" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="0.592593" result="effect1_foregroundBlur_7526_133"/>
            </filter>
            <filter id="filter1_d_7526_133" x="2.49591" y="0.513428" width="43.893" height="64.1766" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="1"/>
            <feGaussianBlur stdDeviation="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7526_133"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7526_133" result="shape"/>
            </filter>
            <linearGradient id="paint0_linear_7526_133" x1="24.4424" y1="2.4092" x2="24.4424" y2="60.7942" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="white" stop-opacity="0.5"/>
            </linearGradient>
            <clipPath id="clip0_7526_133">
            <rect width="48" height="65" fill="white"/>
            </clipPath>
            </defs>
            </svg>`;
        return 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg);
    }
}
