import { TypeOperationCommoditiesEnum } from 'src/app/core/models/enums/type-operation.enum';
import { Anomaly } from '../../anomaly.model';
import { PointV2 } from '../../point-v2.model';
import { SynopticMapPointCommodities } from './synoptic-map-point-commodities.model';
import { SynopticMapThreePoint } from '../synoptic-map-three-point.model';
import { ISynopticMapModel } from '../synoptic-map.interface';
import { SynopticMap } from '../synoptic-map.model';

export class SynopticMapCommodities extends SynopticMap<SynopticMapPointCommodities>
    implements ISynopticMapModel<SynopticMapPointCommodities>
{
    constructor(route: any) {
        super(route);
    }

    public getPoints(): SynopticMapPointCommodities[] {
        return this.points;
    }

    public setPoints(): void {
        this.points = [...this.originalPoints.sort((pointOne: SynopticMapPointCommodities, pointTwo: SynopticMapPointCommodities) =>
            pointOne.order - pointTwo.order
        )];
        if (this.tipoOperacao == TypeOperationCommoditiesEnum.COMPRA_FIXA && this.points.length < 6) {
            const index = this.points.findIndex((point: SynopticMapPointCommodities) => point.type == 'descarga' && !point.date);
            if (index > 0) this.addExtraPointDefault();
        }
        this.addExtraPointInTransit();
        this.addExtraPointAnomalies();
        this.setPointsDescending();
        this.setThreePoints();
    }

    public setPointsDescending(): void {
        const points = [...this.points];

        this.pointsDescending = points.sort((pointOne: SynopticMapPointCommodities, pointTwo: SynopticMapPointCommodities) =>
            pointTwo.order - pointOne.order
        );
    }

    public getPointsDescending(): SynopticMapPointCommodities[] {
        return this.pointsDescending;
    }

    public setThreePoints(): void {
        try {
            if (!this.points) {
                this.setPoints();
            }

            if (this.threePoints.length > 0) {
                return;
            }
            const inTransitPointIndex = this.pointsDescending.findIndex((point: SynopticMapPointCommodities) => point.type === 'in_transit');

            if (inTransitPointIndex !== -1) {
                const inTransitPoint = this.pointsDescending[inTransitPointIndex];

                inTransitPoint.threeCurrent = true;

                this.addLastThreePoints(
                    this.pointsDescending[inTransitPointIndex + 1],
                    inTransitPoint,
                    this.pointsDescending[inTransitPointIndex - 1]
                );

                return;
            }

            for (let i = 0; i < this.pointsDescending.length; i++) {
                const point = this.pointsDescending[i];

                if (point.unloadingNoteDate !== null && point.unloadingNoteDateFinished !== null) {
                    this.addPointsFromUnloadDateFinished(point, i);
                    return;
                } else if (point.unloadingNoteDate !== null && point.unloadingNoteDateFinished === null) {
                    this.addPointsFromUnloadDateWithoutFinished(point, i);
                    return;
                }
            }

            const pointOne = this.pointsDescending[this.pointsDescending.length - 1];
            const pointTwo = this.pointsDescending[this.pointsDescending.length - 2];
            const pointThree = this.pointsDescending[this.pointsDescending.length - 3];

            this.addLastThreePoints(pointOne, pointTwo, pointThree);

        } catch (e) {
            console.error('Preparação da coluna Mapa sinótico falhou', e);
        }
    }

    public getThreePoints(): SynopticMapThreePoint[] {
        return this.threePoints;
    }

    public getPointsMap(): PointV2[] {
        return this.pointsMap;
    }

    public setPointsMap(pointId?: string): void {
        if (!this.points) {
            this.setPoints();
        }

        this.pointsMap = PointV2.from(
            this.points.map((point: SynopticMapPointCommodities, index: number) => {
                let urlPoint = point.urlPoint;
                if (point.type == 'inicio_rota' || point.type == 'fim_rota') {
                    urlPoint = this.points[index - 1].urlPoint;
                }
                return {
                    title: point.title,
                    titleUrl: pointId === point.id ? null : urlPoint,
                    subtitle: (point.type == "transito_carregamento" || point.type == 'transito_descarga') ? null : point.subtitle,
                    date: (point.type == "descarga" && !point.date) ? point.expectedUnloadingNoteDateFinished : point.date,
                    size: point.size,
                    color: point.color,
                    current: point.current,
                    anomaly: point.anomalyName,
                    anomalyTotal: point.anomalies?.length || 0,
                    anomalyUrl: point.anomalyUrl,
                    showing: pointId !== null ? pointId === point.id : point.current,
                    type: point.type
                };
            })
        );
    }

    public addExtraPointInTransit(): void {
        if (this.loadStatus !== 'monitorado') {
            return;
        }

        this.points = this.points.filter(point => point.type !== 'in_transit');

        try {
            const inFactoryUnloading = this.points[1].unloadingNoteDate !== null;

            if (!inFactoryUnloading) {
                this.points.splice(1, 0, new SynopticMapPointCommodities({
                    id: null,
                    ordem: 11,
                    descricao: null,
                    concluido: false,
                    tipoParada: 'in_transit',
                    classificacao: null,
                    nome: null,
                    dataHora: null,
                    dataHoraFinalizacao: null,
                    dataHoraInicio: null,
                    dataHoraFim: null,
                    codigoCliente: null,
                    codigoParada: null,
                    tipoPonto: null,
                    anomalias: null,
                    numeroViagem: this.loadNumber
                }));
            }
        } catch (e) {
            console.error('Não foi criado o ponto extra em trânsito');
        }
    }

    public addExtraPointAnomalies(): void {
        const points = [];

        this.points.forEach((point, index) => {
            points.push(point);

            if (point.date === null || point.type === 'fim_rota' || point.type === 'in_transit') {
                return;
            }

            const nextPoint = this.points[index + 1];
            const dateOne = point.date;
            const dateTwo = nextPoint.date === null ? new Date() : nextPoint.date;
            const anomalies = this.anomalies.filter((anomaly: Anomaly) => anomaly.creationAt >= dateOne && anomaly.creationAt < dateTwo);

            if (anomalies.length) {
                anomalies.forEach((anomaly: Anomaly) => points.push(this.prepareAnomalyPoint(anomaly)));
            }
        });

        this.points = points;
    }

    public addAnomalySignalR(anomalySignalR: any): void {
        try {
            if (this.loadNumber !== anomalySignalR.rota.numeroViagem) {
                return;
            }

            const newAnomaly = new Anomaly({
                id: anomalySignalR.id,
                detalheDescricao: anomalySignalR.detalheDescricao,
                dtCreated: anomalySignalR.dtCreated,
                nome: anomalySignalR.nome,
                status: anomalySignalR.status,
                tipo: anomalySignalR.tipo,
                idParada: anomalySignalR.idParada,
                prioridade: anomalySignalR.prioridade,
                numeroViagem: anomalySignalR.rota.numeroViagem
            });

            const anomalyExistsIndex = this.points.findIndex(point => point.id === newAnomaly.id && point.type === 'anomaly');

            if (anomalyExistsIndex !== -1) {
                return;
            }

            const pointIndex = anomalySignalR.idParada !== null
                ? this.points.findIndex(point => point.id === anomalySignalR.idParada)
                : -1;

            /**
             * FLUXO 1
             * Inserir a anomalia que foi gerada dentro do raio da parada
             */
            if (pointIndex !== -1) {
                const pointFinded = this.points[pointIndex];
                const anomalyIndex = pointFinded.anomalies.findIndex(anomaly => anomaly.id === newAnomaly.id);

                if (anomalyIndex === -1) {
                    const anomalies = [...pointFinded.anomalies];
                    anomalies.push(newAnomaly);
                    pointFinded.anomalies = anomalies;
                } else {
                    pointFinded.anomalies[anomalyIndex] = newAnomaly;
                }

                this.setPointsMap();

                return;
            }

            /**
             * FLUXO 2:
             * Inserir anomalia que foi gerada fora do raio de uma parada
             */
            for (let index = 0; index < this.originalPoints.length; index++) {
                const originalPoint = this.originalPoints[index];

                if (originalPoint.type === 'fim_rota') {
                    continue;
                }

                const nextPoint = this.originalPoints[index + 1];

                if (nextPoint.date === null || originalPoint.date > newAnomaly.creationAt && nextPoint.date < newAnomaly.creationAt) {
                    const pointFindedIndex = this.points.findIndex(point => point.id === originalPoint.id);
                    this.points.splice((pointFindedIndex + 1), 0, this.prepareAnomalyPoint(newAnomaly));
                    this.setPointsMap();
                    return;
                }
            }

        } catch (e) {
            console.error('[ADD ANOMALY SIGNALR]', this.loadNumber, anomalySignalR, e);
        }
    }

    public upsertPointSignalR(pointSignalR: any): void {
        try {
            if (this.loadNumber !== pointSignalR.NumeroViagem) {
                return;
            }

            const pointIndex = this.points.findIndex(point => point.id === pointSignalR.Id);

            if (pointIndex !== -1) {
                const pointFinded = this.points[pointIndex];

                pointFinded.completed = pointSignalR.Concluido;
                pointFinded.unloadingNoteDate = !pointSignalR.DataHora ? null : new Date(pointSignalR.DataHora);
                pointFinded.unloadingNoteDateFinished = !pointSignalR.DataHoraFinalizacao
                    ? null
                    : new Date(pointSignalR.DataHoraFinalizacao);

                this.threePoints = [];
                this.addExtraPointInTransit();
                this.setThreePoints();
                this.setPointsMap();

                return;
            }

            throw new Error('Inserção de novos pontos não implementado');

        } catch (e) {
            console.error('[UPSERT POINT SIGNALR]', this.loadNumber, pointSignalR, e);
        }
    }

    private prepareAnomalyPoint(anomaly: Anomaly): SynopticMapPointCommodities {
        return new SynopticMapPointCommodities({
            id: anomaly.id,
            ordem: null,
            descricao: null,
            concluido: false,
            tipoParada: 'anomaly',
            classificacao: null,
            nome: anomaly.name,
            dataHora: anomaly.creationAt,
            dataHoraFinalizacao: null,
            dataHoraInicio: null,
            dataHoraFim: null,
            codigoCliente: null,
            codigoParada: null,
            tipoPonto: null,
            anomalias: null,
            numeroViagem: this.loadNumber
        });
    }

    /**
     * Adiciona os pontos que estão em andamento
     *
     * @param point1 any
     * @param point2 any
     * @param point3 any
     */
    private addLastThreePoints(
        point1: SynopticMapPointCommodities,
        point2: SynopticMapPointCommodities,
        point3: SynopticMapPointCommodities
    ): void {
        if (this.threePoints.length > 0) {
            return;
        }

        if (this.loadStatus === 'finalizadoManual' || this.loadStatus === 'finalizadoForcado') {
            point1.threeCurrent = point2.threeCurrent = point3.threeCurrent = false;
        }
        this.threePoints.push(
            new SynopticMapThreePoint(point1),
            new SynopticMapThreePoint(point2),
            new SynopticMapThreePoint(point3),
        );
    }

    /**
     * Adiciona os ícones do mapa sinótico a partir do ponto de descarregamento que possue data de finalização
     *
     * @param pointIterate any
     * @param pointsOrdenateDescending any
     *
     * @return void
     */
    private addPointsFromUnloadDateFinished(pointIterate: SynopticMapPointCommodities, index: number): void {
        let pointOne = null;
        let pointTwo = null;
        let pointThree = null;

        if (pointIterate.type === 'fim_rota') {
            pointIterate.threeCurrent = true;

            pointOne = this.pointsDescending[index + 2];
            pointTwo = this.pointsDescending[index + 1];

            this.addLastThreePoints(pointOne, pointTwo, pointIterate);
            return;
        }

        if (pointIterate.type === 'inicio_rota') {
            pointIterate.threeCurrent = true;

            pointTwo = this.pointsDescending[index - 1];
            pointThree = this.pointsDescending[index - 2];

            this.addLastThreePoints(pointIterate, pointTwo, pointThree);
            return;
        }

        pointOne = this.pointsDescending[index - 1];
        pointThree = this.pointsDescending[index - 2];

        pointIterate.threeCurrent = true;

        this.addLastThreePoints(pointOne, pointIterate, pointThree);
    }

    /**
     * Adiciona os ícones do mapa sinótico a partir de uma parada que está em andamento
     *
     * @param pointIterate any
     * @param pointsOrdenateDescending any
     * @param index number
     *
     * @return void
     */
    private addPointsFromUnloadDateWithoutFinished(pointIterate: SynopticMapPointCommodities, index: number): void {
        let pointOne = null;
        let pointTwo = null;
        let pointThree = null;

        if (pointIterate.type === 'inicio_rota') {
            pointIterate.threeCurrent = true;

            pointTwo = this.pointsDescending[index - 1];
            pointThree = this.pointsDescending[index - 2];

            this.addLastThreePoints(pointIterate, pointTwo, pointThree);
            return;
        }

        if (pointIterate.type === 'fim_rota') {
            pointIterate.threeCurrent = true;

            pointTwo = this.pointsDescending[index + 1];
            pointThree = this.pointsDescending[index + 2];

            this.addLastThreePoints(pointThree, pointTwo, pointIterate);
            return;
        }

        pointIterate.threeCurrent = true;

        pointThree = this.pointsDescending[index - 1];
        pointOne = this.pointsDescending[index + 1];

        this.addLastThreePoints(pointOne, pointIterate, pointThree);
    }

    /**
     * Obs: Função apenas para o comportamento de compra fixa por enquanto 
     */
    private addExtraPointDefault() {
        const index: number = this.points.findIndex((point: SynopticMapPointCommodities) => point.type == 'carregamento');
        const carregamento = new SynopticMapPointCommodities({
            id: this.points[index].id,
            ordem: 2,
            descricao: this.points[index].description,
            concluido: true,
            tipoParada: 'carregamento',
            classificacao: this.points[index].classification,
            nome: this.points[index].name,
            dataHora: this.points[0].date,
            dataHoraFinalizacao: this.points[0].unloadingNoteDateFinished,
            dataHoraInicio: this.points[0].expectedUnloadingNoteDate,
            dataHoraFim: this.points[0].expectedUnloadingNoteDateFinished,
            codigoCliente: this.points[index].customerCode,
            codigoParada: this.points[index].code,
            tipoPonto: this.points[index].typePoint,
            anomalias: this.points[index].anomalies,
            numeroViagem: this.loadNumber
        });
        this.points.splice(index, 1);
        this.points.unshift(...[new SynopticMapPointCommodities({
            id: null,
            ordem: 1,
            descricao: null,
            concluido: true,
            tipoParada: 'transito_carregamento',
            classificacao: null,
            nome: null,
            dataHora: this.points[0].date,
            dataHoraFinalizacao: this.points[0].date,
            dataHoraInicio: this.points[0].unloadingNoteDate,
            dataHoraFim: this.points[0].unloadingNoteDateFinished,
            codigoCliente: this.points[0].customerCode,
            codigoParada: this.points[0].code,
            tipoPonto: this.points[0].typePoint,
            anomalias: this.points[0].anomalies,
            numeroViagem: this.loadNumber
        }), carregamento]);
        this.points.splice(3, 0, new SynopticMapPointCommodities({
            id: null,
            ordem: 3,
            descricao: null,
            concluido: true,
            tipoParada: 'transito_descarga',
            classificacao: null,
            nome: null,
            dataHora: this.points[0].date,
            dataHoraFinalizacao: null,
            dataHoraInicio: this.points[0].expectedUnloadingNoteDate,
            dataHoraFim: this.points[0].expectedUnloadingNoteDateFinished,
            codigoCliente: null,
            codigoParada: null,
            tipoPonto: null,
            anomalias: null,
            numeroViagem: this.loadNumber
        }))
        this.points = this.points.map((point: SynopticMapPointCommodities, index) => {
            point.order = index + 1;
            return point;
        })
    }
}
