import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AnomalyStatusEnum } from '../models/enums/anomaly-status.enum';
import { PriorityEnum } from '../models/enums/priority.enum';
import { AnomalyFilter } from '../models/filters/anomaly-filter';
import { BaseService } from './base.service';
import { MessageService } from './message.service';
import { PerfilPermissaoService } from './perfil-permissao.service';

@Injectable({
    providedIn: 'root'
})
export class FilterService extends BaseService<any> {

    filterAnomalia$: Subject<AnomalyFilter> = new Subject();
    filterAnomalia: AnomalyFilter;

    constructor(_http: HttpClient, messageService: MessageService, perfilPermissaoService: PerfilPermissaoService) {
        super(_http, 'selectBoxOptions', messageService, perfilPermissaoService);
    }

    getSelectBoxOptions(campos: string[], logistica: string = null): Observable<any> {
        let params = new HttpParams();
        if (campos.length > 0) {
            campos.forEach(campo => {
                params = params.append("campos", campo);
            });
        }

        if (logistica != null) {
            params = params.append("logistica", logistica);
        }
        return this.http.get(this.url + '', { params })
            .pipe(map((response: any) => response as any));
    }

    getDestinations(label: string) {
        return this.http.get(`${this.url}/bylabel?campos=unidadecomcodigodestinoagro&label=${label}`,)
            .pipe(map((response: any) => response as any));
    }
}
