import {
  Component,
  Input,
  SimpleChanges,
  OnChanges,
  OnInit
} from '@angular/core';
import { Subject } from 'rxjs';
import { LogisticTypeEnum } from 'src/app/core/models/enums/logistic-type.enum';
import { RastroViagem } from 'src/app/core/models/rastro-viagem.model';
import { GmapTravelService } from 'src/app/core/services/gmap-travel.service';
import { Coordinate } from 'src/app/models/google-maps/coordinate.model';
import { TypeChecked } from './load-steps-tracking/load-steps-tracking.component';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnChanges, OnInit {

  @Input() logistic: string;

  @Input() rastro: RastroViagem[] = [];

  @Input() rastroPrevisto: RastroViagem[] = [];

  @Input() step: any;

  @Input() points: any;

  @Input() pointId: string;

  @Input() showArrowDirection: boolean = false;

  @Input() mapPosition: Coordinate;

  @Input() markersPosition = [];

  @Input() currentPoint: RastroViagem;

  @Input() currentPosition: RastroViagem;

  @Input() changeVisualizationOnMapSubject: Subject<{ type: string, checked: boolean }>;

  @Input() distance = null;

  @Input() height: string = '300px';
  @Input() anomalias = [];

  waypoints = [];
  pointsMarker = [];
  AllPointsMarker = [];
  directionsRender = [];
  directionsMarker = [];
  directionsMarkerDisplay = [];

  zoom = 8;
  latitude = -25;
  longitude = -49;
  polylinePoints = [];
  polylinePointsProvided = [];

  // Apenas para primária, quando for carreta fica na cor laranja;
  polylinePointsCarreta = [];

  showAnomalies = true;
  showStepsProvided = true;
  showStepsAchieved = true;
  showTrailProvided = true;
  showTrailAchieved = true;
  showJustified = true;
  showTrailDetail = false;
  radius: number = 150;

  map: google.maps.Map;

  vertices: any[] = [];
  verticesFiltered: any[] = [];

  constructor(
    private gmapTravelService: GmapTravelService,
  ) {
  }

  ngOnInit(): void {
    this.changeVisualizationOnMapSubject?.subscribe((value: { type: string, checked: boolean }) => {
      this.selectTypeVisualization(value);
    });

  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes) {
      this.currentPosition = changes?.currentPosition?.currentValue;
      this.step = changes.step?.currentValue;
      this.distance = changes.distance?.currentValue;
      this.waypoints = [];
      if (this.step?.isContinuousMonitoring) {
        this.getDirectionsContinuousMonitoring();
      } else {
        await this.getDirectionsAndMarker();

      }
      this.drawCircleCurrentpoint();
      this.applyZoom();
    }
  }

  onMapReady = (event: google.maps.Map) => this.map = event;

  onChangeZoom = (zoom: number) => {

    if (zoom === 16 && this.logistic == LogisticTypeEnum.PRIMARY) {
      this.verticesFiltered = this.filterPointsInRadius(100, this.vertices, this.map.getCenter());
    }
    if (zoom < 15) {
      this.verticesFiltered = [];
    }

    if (zoom > 15) {
      this.radius = 150;
      return;
    }


    this.changeRadiusAndZoom();
  }

  filterPointsInRadius(distance: number, points: any, center: google.maps.LatLng) {
    return points.filter((point: any) => {
      var R = 6371;
      var distanceRadius = Math.acos(
        Math.sin(point.latitude) * Math.sin(center.lat()) +
        Math.cos(point.latitude) * Math.cos(center.lat()) *
        Math.cos(center.lng() - point.longitude)
      ) * R;
      return distanceRadius <= distance && !point.estaParado;
    });
  }

  drawCircleCurrentpoint(): void {
    let rastro: any;
    if (this.step?.type == "fim_rota" && !this.step?.isRouteActive && this.step?.isPrimary) {
      rastro = this.rastro[this.rastro.length - 1];
    } else {
      rastro = this.rastro.filter(x => x?.parada?.id === this.pointId && x?.dataHora !== null)[0];
    }
    if (!this.currentPoint) {
      this.currentPoint = !rastro ? null : new RastroViagem(rastro);
    }
  }

  selectTypeVisualization(value: { type: string, checked: boolean }) {
    switch (value.type) {
      case TypeChecked.ANOMALIES:
        this.showAnomalies = value.checked;
        break;
      case TypeChecked.STEPS_PROVIDED:
        this.showStepsProvided = value.checked;
        break;
      case TypeChecked.STEPS_ACHIEVED:
        this.showStepsAchieved = value.checked;
        break;
      case TypeChecked.TRAIL_PROVIDED:
        this.showTrailProvided = value.checked;
        if (this.showTrailProvided) {
          this.showTrailDetail = false;
        }
        break;
      case TypeChecked.TRAIL_ACHIEVED:
        this.showTrailAchieved = value.checked;
        break;
      case TypeChecked.STEPS_JUSTIFIED:
        this.showJustified = value.checked;
        break;
      case TypeChecked.TRAIL_DETAIL:
        this.showTrailDetail = value.checked;
        if (this.showTrailDetail) {
          this.showTrailProvided = false;
          this.directionsMarkerDisplay = this.directionsMarker;
        }
        break;
      default:
        console.error('Type step visualization not found');
    }
    this.pointsMarker = this.showAnomalies
      ? this.AllPointsMarker
      : this.AllPointsMarker.filter(point => !point.isAnomaly);
    this.pointsMarker = this.showStepsProvided
      ? this.pointsMarker
      : this.pointsMarker.filter(point => !point.isMarkerPosition || point.isVisited);
    this.pointsMarker = this.showStepsAchieved
      ? this.pointsMarker
      : this.pointsMarker.filter(point => !point.isFinished);
    this.pointsMarker = this.showJustified
      ? this.pointsMarker
      : this.pointsMarker.filter(point => !point.isJustified);
  }


  getVerticeIcon() {
    const svg = `<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse opacity="0.3" cx="13" cy="12.5551" rx="12" ry="11.976" fill="#003087"/>
    <g filter="url(#filter0_d)">
    <ellipse cx="13" cy="12.5554" rx="5" ry="4.99002" fill="#003087"/>
    <path d="M13 18.5455C16.3118 18.5455 19 15.8655 19 12.5554C19 9.24537 16.3118 6.56543 13 6.56543C9.68818 6.56543 7 9.24537 7 12.5554C7 15.8655 9.68818 18.5455 13 18.5455Z" stroke="white" stroke-width="2"/>
    </g>
    <defs>
    <filter id="filter0_d" x="0" y="0.56543" width="26" height="25.98" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
    <feOffset dy="1"/>
    <feGaussianBlur stdDeviation="3"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
    </filter>
    </defs>
    </svg>`;
    return 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg);
  }

  async getDirectionsAndMarker(): Promise<void> {
    this.gmapTravelService.getDirectionsAndMarker1(
      this.rastro,
      this.markersPosition,
      this.step,
      this.points,
      this.pointId,
      this.currentPosition,
      this.logistic,
      this.anomalias,
    ).then(data => {
      var vertices = this.rastro.filter((p) => p.parada == null);

      this.gmapTravelService.pointsMap = this.rastro.filter((p) => p.parada != null || p.anomalia != null);
      vertices.forEach((data) => {
        var dataLocal: Date = new Date(data.dataHora);
        var velocidade = data.velocidade;
        //var velocidade = 14.0;
        var temperatura = data.temperatura;
        //var temperatura = -18.0;

        this.vertices.push({
          ...data,
          icon: this.getVerticeIcon(),
          date:
            `Data: ${dataLocal.getDate() < 10 ? `0${dataLocal.getDate()}` : dataLocal.getDate()}/${dataLocal.getMonth() < 10 ? `0${dataLocal.getMonth() + 1}` : dataLocal.getMonth() + 1}/${dataLocal.getFullYear()}
          </br>
          Hora: ${dataLocal.getHours()}:${dataLocal.getMinutes() < 10 ? `0${dataLocal.getMinutes()}` : dataLocal.getMinutes()}
          </br>
          Velocidade: ${velocidade != null ? `${velocidade} Km/h` : '-'}
          </br>
          Temperatura: ${temperatura != null ? `${temperatura}°C` : '-'}
          </br>
          Latitude: ${data.latitude}
          </br>
          Longitude: ${data.longitude}
          `,
          velocidade
        });
      })


      this.directionsRender = data?.directions;
      this.directionsMarker = data?.directionsMarker?.length > 1 ? data?.directionsMarker : [];
      this.AllPointsMarker = data?.markers;
      this.pointsMarker = data?.markers;
      if (this.logistic === LogisticTypeEnum.PRIMARY) {
        this.polylinePoints = this.rastro
          // @ts-ignore
          .sort((a, b) => new Date(a?.dataHora) - new Date(b?.dataHora))
          .filter(_ => _?.tipoObj === 0 && _?.estaParado === false && _?.tipoEquipamento === 'CAVALO')
          .map(_ => ({ latitude: _.latitude, longitude: _.longitude }));
        this.polylinePointsCarreta = this.rastro
          // @ts-ignore
          .sort((a, b) => new Date(a?.dataHora) - new Date(b?.dataHora))
          .filter(_ => _?.tipoObj === 0 && _?.tipoEquipamento === 'CARRETA')
          .map(_ => ({ latitude: _.latitude, longitude: _.longitude }));
      } else {
        this.polylinePoints = this.rastro
          // @ts-ignore
          .sort((a, b) => new Date(a?.dataHora) - new Date(b?.dataHora))
          .filter(_ => _?.tipoObj === 0)
          .map(_ => ({ latitude: _.latitude, longitude: _.longitude }));
      }
      this.polylinePointsProvided = this.rastroPrevisto;
      if (this.logistic === LogisticTypeEnum.DISTRIBUITION) {
        this.currentPoint = this.currentPoint || this.step[0];
      } else {
        this.currentPoint = this.currentPoint ? this.currentPoint : this.polylinePoints.length > 0 ? new RastroViagem(this.polylinePoints[0]) : null;
      }
      this.applyZoom();
    });
  }

  getDirectionsContinuousMonitoring(): void {
    this.gmapTravelService.getDirectionsContinuousMonitoring(this.rastro, this.step).then(data => {
      this.directionsRender = data?.directions;
      this.pointsMarker = data?.markers;
    });
  }

  applyZoom(): void {
    if (this.currentPoint) {
      this.zoom = null;
      if (this.mapPosition) {
        this.latitude = this.mapPosition.getLatitude();
        this.longitude = this.mapPosition.getLongitude();
      } else {
        this.latitude = this.currentPoint.latitude;
        this.longitude = this.currentPoint.longitude;
      }
      this.changeRadiusAndZoom();
    }
  }

  changeRadiusAndZoom() {
    if (!this.distance || this.distance < 4000) {
      this.zoom = 16;
      this.radius = 150;
      return;
    }
    if (this.distance >= 60000) {
      this.zoom = 11;
      this.radius = 1250;
      return;
    }
    if (this.distance >= 10000 && this.distance < 60000) {
      this.zoom = 12;
      this.radius = 950;
      return;
    }
    if (this.distance > 8000 && this.distance < 10000) {
      this.zoom = 13;
      this.radius = 750;
      return;
    }
    if (this.distance >= 6000 && this.distance < 8000) {
      this.zoom = 14;
      this.radius = 550;
      return;
    }
    if (this.distance >= 4000 && this.distance < 6000) {
      this.zoom = 15;
      this.radius = 350;
      return;
    }
  }

}
