<ng-container *ngIf="!loading">
  <div class="row" *ngIf="logistic === 'distribuicao'" style="padding-top: 20px; padding-bottom: 20px;">
    <app-checkbox (onchangeEvent)="checkedOption($event)" [options]="statusOptions" width="200px"></app-checkbox>
  </div>
  <div class="row">
    <app-google-map [logistic]="logistic" [showArrowDirection]="showArrowDirection" [points]="points"
      [distance]="distance" [mapPosition]="mapPosition" [rastro]="traceListData"
      [rastroPrevisto]="traceListDataProvided" [step]="step" [currentPosition]="currentPosition" [pointId]="pointId"
      [currentPoint]="currentPoint" [markersPosition]="listCollectionAndDelivery"
      [changeVisualizationOnMapSubject]="changeVisualizationOnMap" [height]="height">
    </app-google-map>
  </div>
</ng-container>