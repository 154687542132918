import { Coordinate } from "src/app/models/google-maps/coordinate.model";
import { PointMarker } from "./point-marker.model";

export class Drawing {
    public id: string;
    public description: string;
    public latLngRef: Coordinate;
    public coordinates: Coordinate[];
    public radius: number;
    public area: number;
    public iconUrl: string;

    private type: any;
    private polygon: any;
    private circle: any;
    private pointMarker: PointMarker;

    constructor(
        id: string,
        description: string,
        latLngRef: Coordinate,
        coordinates: Coordinate[],
        radius: number = 0,
        area: number = 0,
        iconUrl: string = "../../../../assets/icons/maps-pointer/pin_current_.svg"
    ) {
        this.id = id;
        this.description = description;
        this.latLngRef = latLngRef;
        this.coordinates = coordinates;
        this.iconUrl = iconUrl || "../../../../assets/icons/maps-pointer/pin_current_.svg";
        if (this.latLngRef.getLatitude() && this.latLngRef.getLongitude()) {
            this.pointMarker = new PointMarker(
                this.latLngRef,
                {
                    icon: this.iconUrl,
                    label: this.description
                }
            )
        }
        if (radius > 0) {
            this.type = 'circle';
            this.radius = radius;
        }
        if (area > 0) {
            this.type = 'polygon';
            this.area = area;
        }
    }

    public getType(): any {
        return this.type;
    }

    public setPolygon(polygon: any): void {
        this.polygon = polygon;
    }

    public getPolygon(): any {
        return this.polygon;
    }

    public setCircle(circle: any): void {
        this.circle = circle;
    }

    public getCircle(): any {
        return this.circle;
    }

    public getPointMarker(): PointMarker {
        return this.pointMarker
    }

    public setPointMarker(pointMarker: PointMarker): void {
        this.pointMarker = pointMarker;
    }
}