import { Injectable } from '@angular/core';
import { CompanyEnum } from 'src/app/enums/company.enum';
import { LogisticTypeEnum } from 'src/app/core/models/enums/logistic-type.enum';
import { SynopticMapAgro } from 'src/app/models/synoptic-map/agro/synoptic-map-agro.model';
import { SynopticMapCommodities } from 'src/app/models/synoptic-map/commodities/synoptic-map-commodities.model';
import { SynopticMapPrimary } from 'src/app/models/synoptic-map/primary/synoptic-map-primary.model';
import { SynopticMapAgroSlaughterAnimal } from 'src/app/models/synoptic-map/agro/synoptic-map-agro-slaughter-animal.model';
import { SynopticMapDistribution } from 'src/app/models/synoptic-map/distribution/synoptic-map-distribution.model';
import { SynopticMapThreePoint } from 'src/app/models/synoptic-map/synoptic-map-three-point.model';
import { ThreePointResponse } from 'src/app/models/synoptic-map/three-point-response.model';
import { MonitoramentoService } from 'src/app/core/services/monitoramento.service';


@Injectable({
  providedIn: 'root'
})
export class SynopticMapService {

    constructor(private monitoringService: MonitoramentoService) {}

    /**
     * Prepara o mapa sinótico de uma listagem de rotas para logística de agro
     *
     * @param routes any[]
     * @returns SynopticMapAgro[]
     */
    public prepareAgro(routes: any[]): any[] {
        const result = routes.map((route: any) =>
            route?.tipoOperacao == 'Ração' ?
                new SynopticMapAgro(route) :
                new SynopticMapAgroSlaughterAnimal(route)
        );
        return result;
    }

    /**
     * Prepara o mapa sinótico de uma listagem de rotas para logística de commodities
     *
     * @param routes any[]
     * @returns SynopticMapCommodities[]
     */
    public prepareCommodities(routes: any[]): SynopticMapCommodities[] {
        return routes.map((route: any) => new SynopticMapCommodities(route));
    }

    /**
     * Prepara o mapa sinótico de uma listagem de rotas para logística de primária
     *
     * @param routes any[]
     * @returns SynopticMapPrimary[]
     */
     public preparePrimary(routes: any[]): SynopticMapPrimary[] {
        return routes.map((route: any) => new SynopticMapPrimary({
            ...route,
            logistica: LogisticTypeEnum.PRIMARY
        }));
    }

    /**
     * Prepara o mapa sinótico de uma rota para logística de distribuição
     *
     * @param routes any
     * @returns SynopticMapDistribution
     */
     public async prepareDistribution(routes: any | any[]): Promise<SynopticMapDistribution> {
        const route = Array.isArray(routes) ? routes[0] : routes;
        const response = await this.monitoringService.getStepsDistribution(route.numeroViagem).toPromise();
        if (route.empresa === CompanyEnum.MARFRIG) {
            response.paradas.sort((synopticPointA, synopticPointB) => synopticPointA.sequence - synopticPointB.sequence);
        }
        return new SynopticMapDistribution({
            ...route,
            paradas: response.paradas,
            anomalias: response.anomalias,
            logistica: LogisticTypeEnum.DISTRIBUITION
        });
    }

    /**
     * Cria mapa sinótico baseado nos pontos retornados na api de monitoramento
     *
     * @param loadNumber string
     * @param status string
     * @param threePoint ThreePointResponse
     * @returns SynopticMapThreePoint
     */
    getThreePointMap(loadNumber: string, status: string, threePoint: ThreePointResponse): SynopticMapThreePoint[] {
        const loadFinish = status === 'finalizado' || status === 'finalizadoManual' || status === 'finalizadoForcado';
        const list = [
            new SynopticMapThreePoint({
                type: threePoint.rotaMonitoramentoResumoMapaSinoticoParadaAnterior.tipoParada || 'cliente',
                theresAnomaly: threePoint.rotaMonitoramentoResumoMapaSinoticoParadaAnterior.possuiAnomalia,
                title: this.getTitle(threePoint.rotaMonitoramentoResumoMapaSinoticoParadaAnterior),
                subtitle: this.getSubtitle(threePoint.rotaMonitoramentoResumoMapaSinoticoParadaAnterior),
                threeCurrent: false,
                threeFinalizedForced: false,
                urlPoint: `/anomaly/rota/${loadNumber}/tab/3/point/${threePoint.rotaMonitoramentoResumoMapaSinoticoParadaAnterior.id}`
            }),
            new SynopticMapThreePoint({
                type: threePoint.rotaMonitoramentoResumoMapaSinoticoParadaAtual.tipoParada || 'cliente',
                theresAnomaly: threePoint.rotaMonitoramentoResumoMapaSinoticoParadaAtual.possuiAnomalia,
                title: this.getTitle(threePoint.rotaMonitoramentoResumoMapaSinoticoParadaAtual),
                subtitle: this.getSubtitle(threePoint.rotaMonitoramentoResumoMapaSinoticoParadaAtual),
                threeCurrent: !loadFinish,
                threeFinalizedForced: false,
                urlPoint: `/anomaly/rota/${loadNumber}/tab/3/point/${threePoint.rotaMonitoramentoResumoMapaSinoticoParadaAtual.id}`
            }),
            new SynopticMapThreePoint({
                type: threePoint.rotaMonitoramentoResumoMapaSinoticoProximaParada.tipoParada || 'cliente',
                theresAnomaly: threePoint.rotaMonitoramentoResumoMapaSinoticoProximaParada.possuiAnomalia,
                title: this.getTitle(threePoint.rotaMonitoramentoResumoMapaSinoticoProximaParada),
                subtitle: this.getSubtitle(threePoint.rotaMonitoramentoResumoMapaSinoticoProximaParada.nome),
                threeCurrent: loadFinish,
                threeFinalizedForced: loadFinish && status !== 'finalizado',
                urlPoint: `/anomaly/rota/${loadNumber}/tab/3/point/${threePoint.rotaMonitoramentoResumoMapaSinoticoProximaParada.id}`
            })
        ];
        return list;
    }

    private getTitle(point): string {
        switch (point.tipoParada) {
            case 'in_transit':
                return 'Em trânsito';
            case 'cliente':
                return 'Entrega';
            case 'armazem':
                return 'Armazém';
            case 'wc':
                return 'Wc';
            case 'posto_fiscal':
                return 'Posto Fiscal';
            case 'manutencao':
                return 'Manutenção';
            case 'refeicao':
                return 'Refeição';
            case 'prf':
                return 'PRF';
            case 'repouso':
                return 'Repouso';
            case 'abastecimento':
                return 'Abastecimento';
            case 'aduana':
                return 'Aduana';
            case 'transito_descarga':
                return 'Em trânsito Descarga';
            case 'inicio_rota':
                return 'Início da viagem';
            case 'fim_rota':
                return 'Término da viagem';
            default:
                return 'Entrega';
        }
    }

    private getSubtitle(point: any): string {
        if (point.tipoParada === 'inicio_rota' || point.tipoParada === 'fim_rota') {
            return null;
        }

        if (point.codCliente) {
            return `${point.codCliente} - ${point.nome}`;
        }

        return point.nome;
    }
}
