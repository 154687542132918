import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LogisticTypeEnum } from '../models/enums/logistic-type.enum';
import { PerfilPermissaoService } from '../services/perfil-permissao.service';

@Injectable()
export class SubscriptionInterceptor implements HttpInterceptor {
    constructor(private perfilService: PerfilPermissaoService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let subscription: string = null;

        if (request.url.toLowerCase().includes(environment.base_api_distribution_diaria)) {
            subscription = environment.subscription_portal_distribution_diaria;
        } else {
            if (this.perfilService.getCurrentLogisticaRole()?.logistica === LogisticTypeEnum.PRIMARY) {
                subscription = environment.subscription_portal_primary;
            }
            if (this.perfilService.getCurrentLogisticaRole()?.logistica === LogisticTypeEnum.DISTRIBUITION) {
                if (request.url.includes(environment.base_api_distribution_diaria)) {
                    if (environment.subscription_portal_distribution_diaria.length > 0) {
                        subscription = environment.subscription_portal_distribution_diaria;
                    }
                } else {
                    if (environment.subscription_portal_distribution.length > 0) {
                        subscription = environment.subscription_portal_distribution;
                    }
                }
            }
            if ((this.perfilService.getCurrentLogisticaRole()?.logistica === LogisticTypeEnum.AGRONOMY ||
                this.perfilService.getCurrentLogisticaRole()?.logistica === LogisticTypeEnum.COMMODITIES) &&
                environment.subscription_portal_agro_commodities.length > 0) {
                subscription = environment.subscription_portal_agro_commodities;
            }
        }


        if (subscription) {
            const headers = {
                'X-Content-Type-Options': 'nosniff',
                'X-Frame-Options': 'DENY',
                'X-Xss-Protection': '1; mode=block'
            };

            headers[environment.label_authorization_key] = subscription;
            request = request.clone({
                setHeaders: headers
            });
        }
        return next.handle(request);
    }
}
