import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MessageService } from 'src/app/core/services';
import { PerfilPermissaoService } from 'src/app/core/services/perfil-permissao.service';
import { DiariaService } from 'src/app/core/services/diaria.service';
import { DiariaDetail } from 'src/app/models/diarias/diaria-detail.interface';
import { DiariaReason } from 'src/app/models/diarias/diaria-reason.interface';
import { SelectOption } from 'src/app/models/common/angular-material-ui/select-option.interface';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[approval-request-grid-form-row]',
  templateUrl: './approval-request-grid-form-row.component.html',
  styleUrls: ['./approval-request-grid-form-row.component.scss']
})
export class ApprovalRequestGridFormRowComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Output() deleteRow = new EventEmitter<void>();
  @Output() pasteValue = new EventEmitter<{ formControlName: string; values: string[] }>();
  @Output() replicateColumnValue = new EventEmitter<{ formControlName: string, value: string }>();

  details: DiariaDetail | undefined;
  reasons: Array<DiariaReason> = [];
  reasonsToDisplay: Array<DiariaReason> = [];
  subReasonsToDisplay: DiariaReason['subMotivos'] = [];
  responsibleAreaOptions: Array<SelectOption> = [];

  constructor(
    private diariaService: DiariaService,
    private profilePermissionService: PerfilPermissaoService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit(): void {
    (async () => {
      void this.loadResponsibleAreasOptions();
      void this.loadReasons();
    })();
    void this.handleTravelNumberAndPlateChange();
    this.formGroup.get('numeroViagem').valueChanges.subscribe(() => this.handleTravelNumberAndPlateChange());
    this.formGroup.get('placa').valueChanges.subscribe(() => this.handleTravelNumberAndPlateChange());
    this.formGroup.get('areaResponsavel').valueChanges.subscribe((value: string) => {
      this.reasonsToDisplay = this.getReasonsByArea(value);
      this.updateFormControlValueIfDifferent('motivo', '');
    });
    this.formGroup.get('motivo').valueChanges.subscribe((value: string) => {
      this.clearReasonValueIfInvalid(value);
      this.subReasonsToDisplay = this.getSubReasonsByReason(value);
      this.autoSelectSubReasonWhenOnlyOneOptionIsAvailable();
    });
    this.formGroup.get('subMotivo').valueChanges.subscribe((value: string) => {
      const isInvalidSubReason = this.subReasonsToDisplay.filter(subReason => subReason.descricao === value).length === 0;
      if (value && isInvalidSubReason) {
        this.autoSelectSubReasonWhenOnlyOneOptionIsAvailable();
      }
    });
  }

  private clearReasonValueIfInvalid(value: string): void {
    const isResponsibleAreaNotSelected = !this.formGroup.get('areaResponsavel').value;
    const isInvalidReason = this.reasonsToDisplay.filter(reason => reason.descricao === value).length === 0;
    if (value && (isResponsibleAreaNotSelected || isInvalidReason)) {
      this.formGroup.get('motivo').setValue('');
    }
  }

  private autoSelectSubReasonWhenOnlyOneOptionIsAvailable(): void {
    const newSubReasonValue = this.subReasonsToDisplay.length === 1 ? this.subReasonsToDisplay[0].descricao : '';
    this.updateFormControlValueIfDifferent('subMotivo', newSubReasonValue);
  }

  private updateFormControlValueIfDifferent(formControlName: string, newValue: string): void {
    if (this.formGroup.get(formControlName).value !== newValue) {
      this.formGroup.get(formControlName).setValue(newValue);
    }
  }

  private async loadResponsibleAreasOptions(): Promise<void> {
    const areas = await this.diariaService.getAllResponsibleAreas();
    this.responsibleAreaOptions = areas.map(({ descricao, key }) => ({ label: descricao, value: key }));
  }

  private async loadReasons(): Promise<void> {
    this.reasons = await this.diariaService.getAllReasons();
    this.reasonsToDisplay = this.reasons;
  }

  private getReasonsByArea(area: string): Array<DiariaReason> {
    return this.reasons.filter(reason => reason.areaResponsavel === area);
  }

  private getSubReasonsByReason(reasonDescription: string): DiariaReason['subMotivos'] {
    return this.reasons.find(reason => reason.descricao === reasonDescription)?.subMotivos || [];
  }

  emitReplicateColumnValue(formControlName: string): void {
    this.replicateColumnValue.emit({ formControlName, value: this.formGroup.get(formControlName).value });
  }

  async handleTravelNumberAndPlateChange(): Promise<void> {
    const plateFormControl = this.formGroup.get('placa');
    const plateValue = plateFormControl.value;
    const travelNumberFormControl = this.formGroup.get('numeroViagem');
    const travelNumberValue = travelNumberFormControl.value;
    if (travelNumberValue.length < 5 || plateValue.length !== 7) {
      return;
    }

    try {
      const logistic = this.profilePermissionService.getCurrentLogisticaRole().logistica;
      const details = await this.diariaService.getDetailForManualRequest(logistic, travelNumberValue, plateValue);
      if (!details || !details.numeroViagem) {
        plateFormControl.markAsTouched();
        travelNumberFormControl.markAsTouched();
        plateFormControl.setErrors({ notFound: true });
        travelNumberFormControl.setErrors({ notFound: true });
        return this.messageService.error(`Não foi possível encontrar a viagem "${travelNumberValue}" e placa "${plateValue}"`);
      }
      this.details = details;
    } catch (e) {
      this.messageService.error('Ocorreu um erro ao carregar as informações da viagem...');
    }
  }

  handlePastedValue(formControlName: string, event: ClipboardEvent): void {
    event.preventDefault();
    const pastedValue = event.clipboardData.getData('text/plain');
    if (!pastedValue || typeof pastedValue !== 'string') {
      return;
    }

    const rows = pastedValue.split(/\r?\n/g).map(value => value.trim());
    if (formControlName !== 'numeroViagem' || !pastedValue.includes('\t')) {
      return this.pasteValue.emit({ formControlName, values: rows });
    }
    const values = rows.map(row => row.split('\t'));
    const travelNumberRows = values.map(row => row[0]?.trim());
    const plateRows = values.map(row => row[1]?.trim());
    this.pasteValue.emit({ formControlName: 'numeroViagem', values: travelNumberRows });
    this.pasteValue.emit({ formControlName: 'placa', values: plateRows });
  }
}
