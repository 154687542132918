import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Anomaly } from '../models';
import { AnomalyNegociation } from '../models/anomaly-negociation.model';
import { AnomalyFilter } from '../models/filters/anomaly-filter';
import { TableResult } from '../models/table/table-result';
import { BaseService } from './base.service';
import * as moment from 'moment';
import { MessageService } from './message.service';
import { AnomalyDto } from '../models/anomaly-dto.model';
import { PerfilPermissaoService } from './perfil-permissao.service';

@Injectable({
    providedIn: 'root'
})
export class AnomalyService extends BaseService<Anomaly> {

    currentPlaca: Subject<string> = new Subject();

    constructor(
        _http: HttpClient,
        messageService: MessageService,
        perfilPermissaoService: PerfilPermissaoService,
    ) {
        super(_http, 'anomalias', messageService, perfilPermissaoService);
    }

    anomalias: AnomalyDto[] = [];

    findByID(id: string | number): Observable<AnomalyDto> {
        return this.http.get<AnomalyDto>(this.url + `/${id}`, this.header())
            .pipe(map((resp: any) => {
                return resp as AnomalyDto;
            }));
    }

    getAll(filter: AnomalyFilter, isTransportadora: boolean = false): Observable<TableResult<Anomaly>> {
        if (filter == null)
            filter = new AnomalyFilter();

        let params = new HttpParams();

        params = params.append("page", filter.page.toString());
        params = params.append("pageSize", filter.pageSize.toString());

        if (filter.unidades != undefined && filter.unidades.length > 0) {
            filter.unidades.forEach(unidade => {
                params = params.append("unidades", unidade);
            });
        }

        params = params.append("logistica", filter.logistica);

        if (filter.placa != undefined && filter.placa != null)
            params = params.append("placa", filter.placa);

        if (filter.prioridade && filter.prioridade.length > 0) {
            filter.prioridade.forEach(p => {
                params = params.append("prioridade", p.toString());
            });
        }

        if (filter.status && filter.status.length > 0) {
            filter.status.forEach(p => {
                params = params.append("status", p.toString());
            });
        }

        if (filter.dtCreatedStart) {
            let dtCreatedStart: moment.Moment = moment(filter.dtCreatedStart);
            params = params.append("dtCreatedStart", dtCreatedStart.format("yyyy/MM/DD"));
        }

        if (filter.dtCreatedEnd) {
            let dtCreatedEnd: moment.Moment = moment(filter.dtCreatedEnd);
            params = params.append("dtCreatedEnd", dtCreatedEnd.format("yyyy/MM/DD"));
        }

        return this.http.get(this.url + (!isTransportadora ? '' : '/transportadora'), { headers: this.header(), params })
            .pipe(map((response: any) => new TableResult<Anomaly>(response)));
    }

    getByRota(numeroViagem: string, filter: AnomalyFilter): Observable<AnomalyDto[]> {
        if (filter == null)
            filter = new AnomalyFilter();

        let params = new HttpParams();
        params = params.append("page", filter.page.toString());
        params = params.append("pageSize", filter.pageSize.toString());

        return this.http.get(this.url + `/rotas/${numeroViagem}`, { headers: this.header(), params: params })
            .pipe(map((response: any) => {
                this.anomalias = response;
                return response as AnomalyDto[];
            },
            ),
            );
    }

    getTratativasByAnomalia(anomalyId: string): Observable<AnomalyNegociation[]> {
        return this.http.get(this.url + `/${anomalyId}/tratativas`, { headers: this.header() })
            .pipe(map((response: any) => response as AnomalyNegociation[]));
    }

    getContatosByAnomalia(anomaliaId: string): Observable<any[]> {
        return this.http.get(this.url + `/contatos/${anomaliaId}`, { headers: this.header() })
            .pipe(map((response: any) => response));
    }

    addTratativa(anomalyId: string, tratativa: AnomalyNegociation): Observable<any> {
        return this.http.post(this.url + `/${anomalyId}/tratativas`, tratativa, this.header())
            .pipe(map((resp: any) => {
                return resp as any;
            }));
    }

}